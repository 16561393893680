function apenasNumeros(value) {
	let valor = 0;
	if(value != '' && value != null && value != undefined){
		valor = value.toString().replace(/[^0-9]/g, '');
	}
	return valor;
}

function cpfMask(value) {
	if(!value){
		return '';
	}
	return value
		.replace(/\D/g, '')
		.replace(/(\d{3})(\d)/, '$1.$2')
		.replace(/(\d{3})(\d)/, '$1.$2')
		.replace(/(\d{3})(\d{1,2})/, '$1-$2')
		.replace(/(-\d{2})\d+?$/, '$1')
}

function cnpjMask(value) {
	if(!value){
		return '';
	}
	return value
		.replace(/\D/g, '')
		.replace(/(\d{2})(\d)/, '$1.$2')
		.replace(/(\d{3})(\d)/, '$1/$2')
		.replace(/(\d{4})(\d)/, '$1/$2')
		.replace(/(-\d{2})\d+?$/, '$1')
}

function celularMask(value) {
	if (value || false) {
		const onlyDigits = value.replace(/[^0-9]/g, '');
		let searchPrimeiroSegmento = /(\d{4})(\d)/;
		if (onlyDigits.length > 10) {
			searchPrimeiroSegmento = /(\d{5})(\d)/;
		}

		return onlyDigits
			.replace(/\D/g, '')
			.replace(/(\d{2})(\d)/, '($1) $2')
			.replace(searchPrimeiroSegmento, '$1-$2')
			//.replace(/(\d{4})-(\d)(\d{4})/, '$1$2-$3')
			.replace(/(-\d{4})\d+?$/, '$1')
	}

	return null;
}

function cepMask(value) {
	if(!value){
		return '';
	}
	return value
		.replace(/\D/g, '')
		.replace(/(\d{5})(\d)/, '$1-$2')
		.replace(/(-\d{3})\d+?$/, '$1')
}

function pisMask(value) {
	if(!value){
		return '';
	}
	return value
		.replace(/\D/g, '')
		.replace(/(\d{3})(\d)/, '$1.$2')
		.replace(/(\d{5})(\d)/, '$1.$2')
		.replace(/(\d{5}\.)(\d{2})(\d)/, '$1$2-$3')
		.replace(/(-\d{1})\d+?$/, '$1')
}

function identidadeMask(value) {
	return value
		.replace(/\D/g, '')
		.replace(/(\d{2})(\d)/, '$1.$2')
		.replace(/(\d{3})(\d)/, '$1.$2')
		.replace(/(\d{3})(\d)/, '$1-$2')
		.replace(/(-\d{1})\d+?$/, '$1')
}

function realMoeda(value) {
	if (value != '' && value != null && value != undefined) {
		let str = String(value)

		let retorno = str
			.replace(/\D/g, '')
			.replace(/([0-9]{2})$/g, ",$1")

		if (retorno.length > 6)
			retorno = retorno.replace(/([0-9]{3}),([0-9]{2}$)/g, ".$1,$2")

		return retorno
	} else {
		return value
	}

}

const formatarParaMascaraMoeda = function (value) {
	if (value == undefined) {
		value = '0';
	}
	return Number(value).toFixed(2).toString().replace('.', ',');
};

const formatarParaMascaraMoedaComSeparadorDeMilhares = function (value) {
	if (value == undefined) {
		value = '0';
	}
	let valor = Number(value);
	if (Math.abs(valor) < 1000) {
		return formatarParaMascaraMoeda(value);
	}
	let strComDecimais = Number(value).toFixed(2).toString();
	let arrInteiroSeparadoDosDecimais = strComDecimais.split('.');
	arrInteiroSeparadoDosDecimais[0] = arrInteiroSeparadoDosDecimais[0].split('').reverse().join('').match(/.{1,3}/g).join('.').split('').reverse().join('');
	return arrInteiroSeparadoDosDecimais.join(',');
};

const formatarDeMascaraMoeda = function (strValue) {
	if (strValue || false) {
		return Number(strValue.toString().replace(/\./g, '').replace(',', '.'));
	}

	return null;
};

const formatarParaMascaraMoedaComSeparadorRomano = function (value) {
	let valorNumerico = Number(value || 0);
	let valorComVirgula = formatarParaMascaraMoedaComSeparadorDeMilhares(valorNumerico);
	let valorExplodido = valorComVirgula.split('.');
	if (valorNumerico == 0) {
		return valorComVirgula;
	}
	if (valorNumerico >= 1000000000) {
		valorExplodido.pop();
		valorExplodido.pop();
		valorExplodido.pop();

		return valorExplodido.join('.').toString() + 'BI';

	}
	if (valorNumerico >= 1000000) {
		valorExplodido.pop();
		valorExplodido.pop();

		return valorExplodido.join('.').toString() + 'MI';
	}
	if (Math.abs(valorNumerico) > 1000) {
		valorExplodido.pop();
		return valorExplodido.join('.').toString() + 'K';
	}

	return valorComVirgula;
}

export {
	cpfMask,
	cnpjMask,
	celularMask,
	cepMask,
	pisMask,
	identidadeMask,
	realMoeda,
	apenasNumeros,
	formatarDeMascaraMoeda,
	formatarParaMascaraMoeda,
	formatarParaMascaraMoedaComSeparadorRomano,
	formatarParaMascaraMoedaComSeparadorDeMilhares,

}
