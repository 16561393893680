import React, {useState} from 'react';
import Pagination from "react-bootstrap/cjs/Pagination";
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import './index.css';

function TabelaPaginada(props) {
	const [tamanhoPagina, setTamanhoPagina] = useState(props.tamanhoPagina || 10);
	const [pagina, setPagina] = useState(props.paginaInicial || 1);
	const inicioArray = (pagina - 1) * tamanhoPagina;
	const tamanhoArray = ((inicioArray + tamanhoPagina) < props.linhasCorpo.length) ? (inicioArray + tamanhoPagina) : (props.linhasCorpo.length);
	const paginaFinal = Math.ceil(props.linhasCorpo.length / tamanhoPagina);
	const arrayLinhas = props.linhasCorpo.slice(inicioArray, tamanhoArray);
	const itensPaginacao = [];
	for (let numeroPagina = 1; numeroPagina <= paginaFinal; numeroPagina++) {
		itensPaginacao.push(
			<Pagination.Item key={numeroPagina} active={numeroPagina === pagina} onClick={() => {
				setPagina(numeroPagina)
			}}>
				{numeroPagina}
			</Pagination.Item>,
		);
	}

	const opcoesTabela = Object.assign({
		responsive: true,
		bordered: true,
		hover:true
	}, props.optionsTabela);

	return (
		<>
			<div className={" card-table"}>
				<Table className={"tabela-egend"}{...opcoesTabela}>
					<thead className={"thead-dark"}>
					{props.linhasCabecalho}
					</thead>
					<tbody>
					{
						arrayLinhas
					}
					</tbody>
					<tfoot className={"tfoot-dark"}>
					{props.linhasRodape}
					</tfoot>
				</Table>
			</div>
			<div className='d-flex justify-content-between paginacaoTabela'>
				<Form.Group controlId="paginationPageSize">
					<Form.Control type="number" step={props.stepTamanho || 5} min={props.tamanhoMinimo || 5} value={tamanhoPagina}
												size="sm" style={{width: '40%'}}
												onChange={(e) => {
													setTamanhoPagina(e.target.value)
												}}/>
					<Form.Text className="text-muted">
						Itens por página.
					</Form.Text>
				</Form.Group>
				<Pagination>
					<Pagination.First onClick={() => {
						setPagina(1)
					}}/>
					<Pagination.Prev onClick={() => {
						if (pagina !== 1) {
							setPagina(pagina - 1);
						}
					}}/>
					{itensPaginacao}
					<Pagination.Next onClick={() => {
						if (pagina !== paginaFinal) {
							setPagina(pagina + 1);
						}
					}}/>
					<Pagination.Last onClick={() => {
						setPagina(paginaFinal)
					}}/>
				</Pagination>
			</div>
		</>
	)
}

export default TabelaPaginada;