import React, { useState, useEffect } from 'react';
import './index.css';
import Modal from '../../components/Modal';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Swal from "sweetalert2";
import { trackPromise } from "react-promise-tracker";
import ProdutoService from '../../services/ProdutoService';
import ReactTooltip from 'react-tooltip';
import TabelaPaginada from "../../components/TabelaPaginada";
import formatData from '../../helpers/format';
import HeaderPagina from "../../components/HeaderPagina";
import AddButton from "../../components/AddButton";
import { formatarParaMascaraMoedaComSeparadorDeMilhares } from "../../components/InputTextMask";
import { Dropdown } from "react-bootstrap";
import { faPencilAlt, faArrowLeft, faHouseUser, faPhoneSquareAlt, faLaptopMedical, faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import NovoProduto from './NovoProduto';
function Produtos() {
	const [openNovoProduto, setOpenNovoProduto] = useState(false);
	const [produtoSelecionado, setProdutoSelecionado] = useState({});
	const [lista, setLista] = useState([]);

	useEffect(() => {
		buscarProdutos()
	}, []);
	function buscarProdutos() {
		trackPromise(
			ProdutoService.buscarProdutos().then(
				(response) => {
					setLista(response.data);
				},
				(error) => {
					Swal.fire({
						titleText: "Ooops...",
						text: "Ocorreu um erro ao buscar a lista: " + error,
						icon: "error"
					});
				}
			));
	}

	function novoProduto() {
		setProdutoSelecionado({});
		setOpenNovoProduto(true);
	}
	function fecharModal() {
		buscarProdutos({});
		setOpenNovoProduto(false);
	}
	function excluirProduto(id) {
		Swal.fire({
			titleText: "Deseja excluir o produto " + '#' + formatData.zeroPad(id, 3) + "?",
			text: "Tem certeza que deseja excluir o produto " + '#' + formatData.zeroPad(id, 3) + "? Os dados desta não serão mais visíveis no sistema.",
			icon: 'question',
			showCloseButton: false,
			showCancelButton: true,
			focusConfirm: false,
			confirmButtonText: 'Sim, excluir!',
			cancelButtonText: 'Não, mantenha.',
			customClass: {
				confirmButton: 'btn btn-primary mr-3',
				cancelButton: 'btn btn-danger'
			},
			buttonsStyling: false
		}).then(
			(result) => {
				if (result.isConfirmed) {
					trackPromise(
						ProdutoService.excluirProduto(id).then(
							(response) => {
								buscarProdutos({});
								Swal.fire({
									titleText: "Sucesso",
									text: "Produto excluido com sucesso",
									icon: "success"
								});

							},
							(error) => {
								Swal.fire({
									titleText: "Ooops...",
									text: "Ocorreu um erro ao excluir: " + error,
									icon: "error"
								});
							}
						));
				}
			})
	}
	const linhas = lista.map((produto, index) => <tr key={produto.id}>
		<td>
			{formatData.zeroPad(produto.id, 3)}
		</td>
		<td>
			{produto.nome}
		</td>
		<td>
			R$ {formatarParaMascaraMoedaComSeparadorDeMilhares(produto.valor)}
		</td>
		<td>
			{produto.tipo_produto_nome}
		</td>
		<td >
			<Dropdown className="dropdownNaoDistribuido">
				<Dropdown.Toggle variant="primary">
					<FontAwesomeIcon
						icon={faPencilAlt} />
				</Dropdown.Toggle>
				<Dropdown.Menu>
					<Dropdown.Item as="button" onClick={() => { setOpenNovoProduto(true); setProdutoSelecionado(produto) }}
						style={{ color: '#000' }}><FontAwesomeIcon
							icon={faEdit} style={{ marginRight: '30px' }} />Alterar Produto
                            </Dropdown.Item>
					<Dropdown.Item as="button" onClick={() => { excluirProduto(produto.id) }}
						style={{ color: '#000' }}><FontAwesomeIcon
							icon={faTrash} style={{ marginRight: '30px' }} />Excluir Produto
                            </Dropdown.Item>
				</Dropdown.Menu>
			</Dropdown>
		</td>
	</tr>);

	const thead = (
		<tr>
			<th>
				Código
        	</th>
			<th>
				Nome
        	</th>
			<th>
				Valor
        	</th>
			<th>
				Tipo
        	</th>
			<th className="text-center" style={{ width: '10%' }}>Ações</th>
		</tr>
	);
	return <>
		<HeaderPagina titulo="Produtos/Serviços" />
		<AddButton clickBotao={() => novoProduto()} />
		<div className="containerTabela">
			<TabelaPaginada linhasCabecalho={thead} optionsTabela={{}} linhasCorpo={linhas} />
		</div>
		<ReactTooltip />
		<Modal open={openNovoProduto} close={() => fecharModal(false)}
			content={<NovoProduto produto={produtoSelecionado} atualizaLista={() => buscarProdutos()} />}
			titulo="Produto/Serviço" />
	</>
}
export default Produtos;
