import React, { useState, useEffect } from "react";
import { Form, Row, Container, Col } from "react-bootstrap";
import "./index.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave } from "@fortawesome/free-solid-svg-icons";
import ProdutoService from "../../../services/ProdutoService";
import { trackPromise } from "react-promise-tracker";
import Swal from "sweetalert2";

export default function NovoProduto({ history, produto, atualizaLista }) {
  const [nome, setNome] = useState(produto.nome);
  const [valor, setValor] = useState(produto.valor);
  const [prazo, setPrazo] = useState(produto.prazo);
  const [descricao, setDescricao] = useState(produto.descricao);
  const [tipo, setTipo] = useState(produto.tipo_produto);
  const [diasCobranca, setDiasCobranca] = useState(produto.dias_cobranca);

  const [tipos, setTipos] = useState([]);

  useEffect(() => {
    trackPromise(
      ProdutoService.buscarTipos().then(
        (response) => {
          setTipos(response.data);
        },
        (error) => {
          Swal.fire({
            titleText: "Ooops...",
            text: "Ocorreu um erro ao buscar dependências: " + error,
            icon: "error",
          });
        }
      )
    );
  }, []);

  function salvarProduto() {
    const dados = {
      nome: nome,
      valor: valor,
      prazo: prazo,
      descricao: descricao,
      tipo_produto: tipo,
      dias_cobranca: diasCobranca,
    };
    ProdutoService.salvarProduto(dados, produto.id).then(
      (response) => {
        Swal.fire({
          titleText: "Sucesso",
          text: "Produto salvo com sucesso",
          icon: "success",
        });
      },
      (error) => {
        Swal.fire({
          titleText: "Ooops...",
          text: "Ocorreu um erro ao salvar o produto: " + error,
          icon: "error",
        });
      }
    );
  }

  return (
    <>
      <Form className="formCadastro">
        <Row>
          <Form.Group as={Col} controlId="formNome">
            <Form.Label>Tipo: </Form.Label>
            <Form.Control
              as="select"
              value={tipo}
              custom
              onChange={(event) => setTipo(event.target.value)}
            >
              <option>Selecione o tipo</option>
              {tipos.map((tipo, index) => (
                <option value={tipo.id}>{tipo.nome}</option>
              ))}
            </Form.Control>
          </Form.Group>
        </Row>
        <Row>
          <Form.Group as={Col} md="6" controlId="formNome">
            <Form.Label>Nome: </Form.Label>
            <Form.Control
              type="text"
              value={nome}
              placeholder="Digite o nome"
              onChange={(event) => setNome(event.target.value)}
            />
          </Form.Group>
          <Form.Group as={Col} md="2" controlId="formNome">
            <Form.Label>Prazo (em dias): </Form.Label>
            <Form.Control
              type="number"
              value={prazo}
              placeholder="0 para indefinido"
              onChange={(event) => setPrazo(event.target.value)}
            />
          </Form.Group>
          <Form.Group as={Col} md="2" controlId="formNome">
            <Form.Label>Dias até a cobrança </Form.Label>
            <Form.Control
              type="number"
              value={diasCobranca}
              placeholder="Digite o período para a validade do boleto"
              onChange={(event) => setDiasCobranca(event.target.value)}
            />
          </Form.Group>
          <Form.Group as={Col} md="2" controlId="formNome">
            <Form.Label>Valor: </Form.Label>
            <Form.Control
              type="text"
              value={valor}
              placeholder="Digite o valor do produto/servico"
              onChange={(event) => setValor(event.target.value)}
            />
          </Form.Group>
        </Row>
        <Row>
          <Form.Group as={Col} controlId="formNome">
            <Form.Label>Descrição: </Form.Label>
            <textarea
              name="descricao"
              className="form-control"
              id="descricao"
              value={descricao}
              placeholder="Digite a descrição"
              onChange={(event) => setDescricao(event.target.value)}
              cols="30"
              rows="10"
            ></textarea>
          </Form.Group>
        </Row>
        <Row className="formFooter">
          <Col>
            <button
              type="button"
              onClick={() => salvarProduto()}
              className="btn btn-success"
            >
              <FontAwesomeIcon icon={faSave} style={{ marginRight: "15px" }} />
              Salvar
            </button>
          </Col>
        </Row>
      </Form>
    </>
  );
}
