import React, { useState, useEffect } from 'react';
import { Form, Row, Container, Col } from 'react-bootstrap';
import './index.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave } from "@fortawesome/free-solid-svg-icons";
import DashboardService from '../../../services/DashboardService';
import { trackPromise } from "react-promise-tracker";
import Swal from "sweetalert2";
import usuarioService from '../../../services/UsuarioService';
import formatData from '../../../helpers/format';
import { formatarParaMascaraMoedaComSeparadorDeMilhares } from "../../../components/InputTextMask";

export default function NovaCobranca({ history, cobranca, atualizaLista }) {
    const [valor, setValor] = useState(cobranca.value);
    const [cliente, setCliente] = useState(cobranca.customer);
    const [prazo, setPrazo] = useState(cobranca.dueDate);
    const [ciclo, setCiclo] = useState(cobranca.cycle);
    const [id, setId] = useState(cobranca.id);
    const [descricao, setDescricao] = useState(cobranca.description);
    const [usuarios, setUsuarios] = useState([]);

    function salvarCobranca() {
        const dados = {
            'value': valor,
            'dueDate': prazo,
            'cliente': cliente,
            'description': descricao,
            'cycle': ciclo,
        };
        trackPromise(
            DashboardService.salvarCobranca(dados, id).then(
                (response) => {
                    if (response.data.valido) {
                        Swal.fire({
                            titleText: "Sucesso",
                            text: "Cobrança salva com sucesso",
                            icon: "success"
                        });
                        atualizaLista();
                    } else {
                        Swal.fire({
                            titleText: "Ooops...",
                            text: response.data.mensagem,
                            icon: "error"
                        });
                    }

                },
                (error) => {
                    Swal.fire({
                        titleText: "Ooops...",
                        text: "Ocorreu um erro ao salvar a cobrança: " + error,
                        icon: "error"
                    });
                }
            ))
    }
    useEffect(() => {
        buscarDashboards()
    }, []);
    function buscarDashboards() {
        trackPromise(
            usuarioService.buscarUsuarios().then(
                (response) => {
                    setUsuarios(response.data);
                },
                (error) => {
                    Swal.fire({
                        titleText: "Ooops...",
                        text: "Ocorreu um erro ao buscar a lista de usuários: " + error,
                        icon: "error"
                    });
                }
            )
        );
    }


    return <>

        <Form className="formCadastro">
            {cobranca.value === undefined ? <Row>
                <Form.Group as={Col} controlId="formNome">
                    <Form.Label>Cliente: </Form.Label>
                    <Form.Control as="select" value={cliente} custom onChange={(event) => setCliente(event.target.value)}>
                        <option>Selecione</option>
                        {usuarios.map((usuario, index) => <option key={usuario.id} value={usuario.documento}>{usuario.nome} {usuario.sobrenome} ({usuario.documento})</option>)}
                    </Form.Control>
                </Form.Group>
            </Row> : <></>}
            <Row>
                <Form.Group as={Col} controlId="formNome">
                    <Form.Label>Ciclo: </Form.Label>
                    <Form.Control as="select" value={ciclo} custom onChange={(event) => setCiclo(event.target.value)}>
                        <option>Único pagamento</option>
                        <option value="MONTHLY">Mensal</option>
                        <option value="WEEKLY">Semanal</option>
                        <option value="SEMIANNUALLY">Semestral</option>
                        <option value="YEARLY">Anual</option>
                    </Form.Control>
                </Form.Group>
                <Form.Group as={Col} controlId="formNome">
                    <Form.Label>Vencimento</Form.Label>
                    <Form.Control type="date" value={prazo} placeholder="Digite o vencimento do boleto" onChange={(event) => setPrazo(event.target.value)} />
                </Form.Group>
                <Form.Group as={Col} controlId="formNome">
                    <Form.Label>Valor: </Form.Label>
                    <Form.Control type="text" value={valor} placeholder="Digite o valor da cobrança" onChange={(event) => setValor(event.target.value)} />
                </Form.Group>
            </Row>
            <Row>
                <Form.Group as={Col} controlId="formNome">
                    <Form.Label>Descrição: </Form.Label>
                    <textarea name="descricao" className="form-control" id="descricao" value={descricao} placeholder="Digite a descrição" onChange={(event) => setDescricao(event.target.value)} cols="30" rows="10"></textarea>
                </Form.Group>
            </Row>
            <Row className="formFooter">
                <Col>
                    <button type="button" onClick={() => salvarCobranca()} className="btn btn-success"><FontAwesomeIcon icon={faSave}
                        style={{ marginRight: '15px' }} />Salvar
					</button>
                </Col>
            </Row>

        </Form>
    </>
}