import React, { useState, useEffect } from 'react';
import api from '../../services/api';
import '../Register/index.css';
import logo from '../../assets/logo.png';
import { Form, Row, Container, Col } from 'react-bootstrap';
import { cpfMask, celularMask, cepMask } from "../../components/InputTextMask";
import utilsService from '../../services/utils';
import Swal from "sweetalert2";
import CpfCnpj from "@react-br-forms/cpf-cnpj-mask";
import { useParams } from "react-router";


export default function Recupera({ history }) {
    const [password, setPassword] = useState('');
    const [passwordConfirm, setPasswordConfirm] = useState('');
    const [error, setError] = useState(false);
    let params = useParams();
    async function handleSubmit(event) {
        event.preventDefault();
        const token = params.token;
        const response = await api.post('/usuario/alterar-senha', {
            password: password,
            token: token,
        });
        if (response.status == 202) {
            Swal.fire({
                titleText: "Sucesso!",
                text: response.data,
                icon: "success"
            });
            history.push('/login');
        } else {
            Swal.fire({
                titleText: "Ocorreu um erro ao alterar a senha",
                text: response.data,
                icon: "error"
            });
            setError(true);
        }


    }
    return (<div className="login">
        <div className="imagemLogin">
            <div className="imagemFundoLogin"></div>
        </div>
        <div className="container">
            <div className="logo-egend">
                <img src={logo} alt="EGEND" />
            </div>
            <div className="content">

                <p><strong>Altere sua senha: </strong></p>

                <Form className="formCadastro" onSubmit={handleSubmit}>
                    <Row>
                        <Form.Group as={Col} md="12" >
                            <Form.Label>Senha: </Form.Label>
                            <Form.Control type="password" placeholder="Digite a sua senha" onChange={(event) => setPassword(event.target.value)} required />
                        </Form.Group>
                    </Row>
                    <Row>
                        <Form.Group as={Col} md="12" >
                            <Form.Label>Confirmar Senha: </Form.Label>
                            <Form.Control type="password" placeholder="Digite a sua senha" onChange={(event) => setPasswordConfirm(event.target.value)} required />
                        </Form.Group>
                    </Row>
                    {password != passwordConfirm ? <p className="error" >A senha está divergente da confirmação.</p> : <></>}

                    <div className="buttons">
                        <button className="btn btn-submit" disabled={password != passwordConfirm} type="submit">Alterar senha</button>

                    </div>

                </Form>
            </div>

        </div>
    </div>
    )
}
