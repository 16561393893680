import React, { Component } from 'react';
import '../MenuLateral/index.css';
import {
	faBars,
	faPowerOff,
	faColumns,
	faFile,
	faUsers,
	faFileInvoice,
	faReceipt,
	faBoxes,
	faPlusSquare,
	faPercentage,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactTooltip from 'react-tooltip';
import { isMobile } from "react-device-detect";

export default class MenuTopo extends Component {
	constructor(props) {
		super(props);
		this.state = {
			open: true,
		}
		this.mudarEstado = this.mudarEstado.bind(this);
	}

	mudarEstado(e) {
		e.preventDefault();
		const novoOpen = !(this.state.open);
		this.setState({ open: novoOpen });
	}

	logout(e) {
		e.preventDefault();
		localStorage.clear();
		window.location.reload(false);
	}

	render() {
		const classeAsideSidebar = 'sidebar' + (this.state.open && !isMobile ? '' : ' hide');
		const classeSidebarWrapper = 'sidebar-wrapper' + (this.state.open && !isMobile ? '' : ' is-closed');
		const classeBackground = 'backgroundSidebar' + (this.state.open && !isMobile ? '' : ' is-closed');
		var tipoUsuario = localStorage.getItem('tipoEgendDrive');

		return (
			<>
				<aside className={classeAsideSidebar}>
					{!this.state.open ?
						<ReactTooltip /> : <></>}
					<div className={classeSidebarWrapper}>
						<div className="sidebar-title">
							<span>Menu</span>
							<a href="#" data-tip="Menu" onClick={this.mudarEstado}>
								<FontAwesomeIcon icon={faBars} />
							</a>
						</div>
						<div className="siderbar-holder">
							<div className="menu-structure">
								<ul>

									<li>
										<a href="/">
											<FontAwesomeIcon color="#fff" icon={faFileInvoice} data-tip="Dashboard" />
											<p>Dashboard</p>
										</a>
									</li>
									{tipoUsuario == 1 ? <>
										<li>
											<a href="/produtos">
												<FontAwesomeIcon color="#fff" icon={faPlusSquare} data-tip="Produtos" />
												<p>Cadastro de Produtos</p>
											</a>
										</li>
										<li>
											<a href="/cupom">
												<FontAwesomeIcon color="#fff" icon={faPercentage} data-tip="Cupons" />
												<p>Cadastro de Cupons</p>
											</a>
										</li>
									</> : <></>}
									<li>
										<a href="/lista-produtos">
											<FontAwesomeIcon color="#fff" icon={faReceipt} data-tip="Produtos Pedido" />
											<p>Novo Pedido</p>
										</a>
									</li>

									<li>
										<a href="/pedidos">
											<FontAwesomeIcon color="#fff" icon={faBoxes} data-tip="Pedidos" />
											<p>Pedidos</p>
										</a>
									</li>
									<li>
										<a href="/arquivos">
											<FontAwesomeIcon color="#fff" icon={faFile} data-tip="Arquivos" />
											<p>Arquivos</p>
										</a>
									</li>
								</ul>
							</div>
							<div className="info-structure">
								<ul>
									{tipoUsuario == 1 ? <>

										<li>
											<a href="/usuarios">
												<FontAwesomeIcon color="#fff" icon={faUsers} data-tip="Usuários" />
												<p>Usuários</p>
											</a>
										</li>
									</> : <></>}

									<li onClick={this.logout}>
										<a href="#">
											<FontAwesomeIcon icon={faPowerOff} color="#fff" data-tip="Sair" />
											<p>Sair</p>
										</a>
									</li>
								</ul>
								<div className="copy">
									Desenvolvido por <a href="https://www.egend.com.br/" target="_blank">EGEND</a>
								</div>
							</div>
						</div>
					</div>
				</aside>
				<div className={classeBackground}>

				</div>
			</>
		)
	}
}