import React from 'react';
import styled, { keyframes } from 'styled-components';

const StyledEgendLoadWrapper = styled.div`
	position: ${props=>props.loadWrapperPosition || 'fixed'};
  top: 0;
  width: 100%;
  height: 100%;
  left: 0;
  z-index: 1050;
  background-color: rgba(0,0,0,.5);
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledEgendLoadEllipsisWrapper = styled.div`
	display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
  background-color: #fff;
  border-radius: 50%;
`;

const ldsEllipsis1 = keyframes`
	0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }`;

const ldsEllipsis2 = keyframes`
 	0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(19px, 0);
  }`;

const ldsEllipsis3 = keyframes`
	0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }`;

const StyledEgendLoadEllipsis = styled.div`
	position: absolute;
  top: 27px;
  width: 11px;
  height: 11px;
  border-radius: 50%;
  background: #ccd;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
  &:nth-child(1) {
		left: 6px;
		animation: ${ldsEllipsis1} 0.6s infinite;
	};
	&:nth-child(2) {
		left: 6px;
		animation: ${ldsEllipsis2} 0.6s infinite;
		background: #e11933;
	};
	&:nth-child(3) {
		left: 26px;
		animation: ${ldsEllipsis2} 0.6s infinite;
	};
	&:nth-child(4) {
		left: 45px;
		animation: ${ldsEllipsis3} 0.6s infinite;
	}
 `;

function EgendLoading(props) {

	return (
		<StyledEgendLoadWrapper {...props}>
			<StyledEgendLoadEllipsisWrapper>
				<StyledEgendLoadEllipsis />
				<StyledEgendLoadEllipsis />
				<StyledEgendLoadEllipsis />
			</StyledEgendLoadEllipsisWrapper>
		</StyledEgendLoadWrapper>);
}

export default EgendLoading;