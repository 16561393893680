import axios from "axios";

let token = "";
let sistema = 1;
if (localStorage.getItem("tokenEgendDrive")) {
  token = localStorage.getItem("tokenEgendDrive");
}
if (localStorage.getItem("sistemaIbraga")) {
  sistema = localStorage.getItem("sistemaIbraga");
}
const api = axios.create({
  baseURL: "https://api.sistema.ibraga.com.br",
  headers: {
    Authorization:
      "6585a6442b80a1da74e816c5e6442c98d52a087530bee317debf42816d0945ea",
    Token: token,
    Sistema: sistema,
  },
});

export default api;
