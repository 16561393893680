import api from './api';

const cupomService = {
	buscarCupons: () => {
		return api.get('/cupom');
	},
	buscarTipos: () => {
		return api.get('/cupom/tipos');
	},
	buscarCategorias: () => {
		return api.get('/cupom/categorias');
	},
	buscarCupom: (codigo) => {
		return api.get('/cupom/code/'+codigo);
	},
	contabilizaCupom: (codigo) => {
		return api.get('/cupom/contabiliza/'+codigo);
	},
	salvarCupom: (cupom, id) => {
		let uri = '/cupom';
		let method = 'post';
		if (id != undefined) {
			uri = '/cupom/' + id;
			method = 'put'
		}
		return api[method].call(api, uri, { cupom: cupom });
	},
	excluirCupom: (id) => {
		let dados = {
			ativo: '0',
			id: id
		};
		return api['put'].call(api, '/cupom/' + id, { cupom: dados });
	},
};

export default cupomService;