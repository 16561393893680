import React, { useState, useEffect } from 'react';
import { Form, Row, Container, Col } from 'react-bootstrap';
import './index.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPercentage, faSave, faShoppingCart } from "@fortawesome/free-solid-svg-icons";
import ProdutoService from '../../../services/ProdutoService';
import { trackPromise } from "react-promise-tracker";
import Swal from "sweetalert2";
import { formatarParaMascaraMoedaComSeparadorDeMilhares } from "../../../components/InputTextMask";
import pedidoService from '../../../services/PedidoService';
import cupomService from '../../../services/CupomService';

export default function NovoPedido({ history, produto, atualizaLista }) {
    const [observacao, setObservacao] = useState("");
    const [cupom, setCupom] = useState("");
    const [cupomValido, setCupomValido] = useState(false);
    const [tipo, setTipo] = useState(0);
    const [valorCupom, setValorCupom] = useState(0);
    const [valorFinal, setValorFinal] = useState(0);

    const [tipos, setTipos] = useState([]);

    useEffect(() => {
        setValorFinal(produto.valor)
        trackPromise(
            pedidoService.buscarTipos().then(
                (response) => {
                    setTipos(response.data);
                },
                (error) => {
                    Swal.fire({
                        titleText: "Ooops...",
                        text: "Ocorreu um erro ao buscar dependências: " + error,
                        icon: "error"
                    });
                }
            )
        )
    }, []);
    function encontraTipoPedido() {
        let index = tipos.findIndex((tipoPedido) => {
            return tipoPedido.id == tipo;
        });
        return tipos[index].legenda;
    }
    function buscarCupom() {
        trackPromise(
            cupomService.buscarCupom(cupom).then(
                (response) => {
                    if (response.status == 200) {
                        Swal.fire({
                            titleText: "Sucesso!",
                            text: "Cupom adicionado com sucesso",
                            icon: "success"
                        });
                        if (response.data.tipo == "P") {
                            let desconto = parseFloat((produto.valor * response.data.valor) / 100);
                            let total = parseFloat(produto.valor) - parseFloat(desconto);
                            setValorFinal(total)
                        } else {
                            setValorCupom(response.data.valor)
                            setValorFinal(produto.valor - response.data.valor)

                        }
                        setCupomValido(true);
                    } else {
                        Swal.fire({
                            titleText: "Ooops...",
                            text: "Cupom inválido ou já atingiu o limite de usos",
                            icon: "warning"
                        });
                    }
                },
                (error) => {
                    Swal.fire({
                        titleText: "Ooops...",
                        text: "Ocorreu um erro ao buscar o cupom, entre em contato conosco",
                        icon: "error"
                    });
                }
            )
        )
    }
    function salvarPedido() {
        const dados = {
            'observacao': observacao,
            'valor_referencia': produto.valor,
            'prazo_referencia': produto.prazo,
            'id_referencia': produto.id,
            'tipo_pedido': tipo,
        };
        if (observacao != "") {
            dados.observacao = observacao
        }
        if (cupom != "") {
            dados.cupom = cupom
        }
        Swal.fire({
            titleText: "Confirmação de pedido",
            text: "Você confirma que está realizando " + encontraTipoPedido() + " de " + produto.nome,
            icon: 'question',
            showCloseButton: false,
            showCancelButton: true,
            focusConfirm: false,
            confirmButtonText: 'Sim, realizar pedido!',
            cancelButtonText: 'Não, cancelar solicitação.',
            customClass: {
                confirmButton: 'btn btn-primary mr-3',
                cancelButton: 'btn btn-danger'
            },
            buttonsStyling: false
        }).then(
            (result) => {
                if (result.isConfirmed) {
                    trackPromise(
                        pedidoService.salvarPedido(dados).then(
                            (response) => {
                                Swal.fire({
                                    titleText: "Sucesso",
                                    text: "Pedido realizado com sucesso",
                                    icon: "success"
                                });
                                if (cupom != "") {
                                    cupomService.contabilizaCupom(cupom);
                                }
                                atualizaLista();
                            },
                            (error) => {
                                Swal.fire({
                                    titleText: "Ooops...",
                                    text: "Ocorreu um erro ao realizar pedido, entre em contato conosco.",
                                    icon: "error"
                                });
                            }
                        )
                    )
                }
            })
    }


    return <>

        <Form className="formCadastro">
            <Row>
                <Form.Group as={Col} controlId="formNome">
                    <Form.Label>Tipo: </Form.Label>
                    <Form.Control as="select" value={tipo} custom onChange={(event) => setTipo(event.target.value)} required>
                        <option value="0">O que você deseja solicitar? </option>
                        {tipos.map((tipo, index) => <option key={tipo.id} value={tipo.id}>{tipo.nome}</option>)}
                    </Form.Control>
                </Form.Group>
            </Row>
            <Row>
                <Form.Group as={Col} md="2" controlId="formNome">
                    <Form.Label>Tipo: </Form.Label>
                    <Form.Control type="text" value={produto.tipo_produto_nome} disabled />
                </Form.Group>
                <Form.Group as={Col} md="6" controlId="formNome">
                    <Form.Label>Produto: </Form.Label>
                    <Form.Control type="text" value={produto.nome} disabled />
                </Form.Group>
                <Form.Group as={Col} md="2" controlId="formNome">
                    <Form.Label>Prazo de finalização: </Form.Label>
                    <Form.Control type="text" value={produto.prazo != 0 ? produto.prazo + " dia(s)" : "Indefinido"} disabled />
                </Form.Group>
                <Form.Group as={Col} md="2" controlId="formNome">
                    <Form.Label>Valor: </Form.Label>
                    <Form.Control type="text" value={"R$ " + formatarParaMascaraMoedaComSeparadorDeMilhares(produto.valor)} disabled />
                </Form.Group>
            </Row>
            <Row>
                <Form.Group as={Col} controlId="formNome">
                    <Form.Label>Observação sobre o pedido: </Form.Label>
                    <textarea name="observacao" className="form-control" id="observacao" value={observacao} placeholder="Digite uma observação" onChange={(event) => setObservacao(event.target.value)} cols="30" rows="10"></textarea>
                </Form.Group>
            </Row>
            <Row>
                <Form.Group as={Col} md="2" controlId="formNome">
                    <Form.Label>Cupom: </Form.Label>
                    <Form.Control type="text" value={produto.cupom} placeholder="Digite um cupom" onChange={(event) => setCupom(event.target.value)} disabled={cupomValido} />
                </Form.Group>
                <Form.Group controlId="formNome" className="botaoCupom">
                    <button type="button" onClick={() => buscarCupom()} className="btn btn-success" disabled={cupomValido}><FontAwesomeIcon icon={faPercentage}
                        style={{ marginRight: '15px' }} />Adicionar
                    </button>
                </Form.Group>
            </Row>
            <Row className="formFooter">
                <Col>
                    <Form.Group style={{   marginRight: "2%", width: "12%"}} controlId="formNome">
                        <Form.Label>Valor final: </Form.Label>
                        <Form.Control style={{ textAlign: "center" }} type="text" value={"R$ " + formatarParaMascaraMoedaComSeparadorDeMilhares(valorFinal)} placeholder="Valor final" disabled={true} />
                    </Form.Group>
                   
                    <button type="button" style={{ marginTop: '8px'}} onClick={() => salvarPedido()}  className="btn btn-success" disabled={tipo == 0 ? true : false}><FontAwesomeIcon icon={faShoppingCart}
                        style={{ marginRight: '15px' }} />Realizar pedido
                    </button>
                </Col>
            </Row>

        </Form>
    </>
}