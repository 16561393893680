const formatData = {
    formatDate: (date) => {
        var date = new Date(date)
        var seconds = Math.floor((new Date() - date) / 1000);
        var interval = seconds / 31536000;
        if (interval > 1) {
            return Math.floor(interval) + " anos atrás";
        }
        interval = seconds / 2592000;
        if (interval > 1) {
            return Math.floor(interval) + " mes(es) atrás";
        }
        interval = seconds / 86400;
        if (interval > 1) {
            return Math.floor(interval) + " dia(s) atrás";
        }
        interval = seconds / 3600;
        if (interval > 1) {
            return Math.floor(interval) + " hora(s) atrás";
        }
        interval = seconds / 60;
        if (interval > 1) {
            return Math.floor(interval) + " minuto(s) atrás";
        }
        return Math.floor(seconds) + " segundo(s) atrás";
    },

    contarAtraso: (date) => {
        var date = new Date(date)
        var dias = 0;
        var seconds = Math.floor((new Date() - date) / 1000);
        var interval = seconds / 31536000;
        interval = seconds / 86400;
        if (interval > 1) {
            if (Math.floor(interval) > 3){
                return Math.floor(interval) - 3;
            }
        }
    },

    zeroPad: (num, places) => {
        return String(num).padStart(places, '0')
    },

    formataMysql: (data) => {
        if (data != undefined && data != '') {

            var dia = data.split("/")[0];
            var mes = data.split("/")[1];
            var ano = data.split("/")[2];

            return ano + '-' + ("0" + mes).slice(-2) + '-' + ("0" + dia).slice(-2);
        }else{
            return null;
        }

    },
    formataBr: (data) => {
        if (data != undefined && data != '') {

            var dia = data.split("-")[2];
            var mes = data.split("-")[1];
            var ano = data.split("-")[0];

            return ("0" + dia).slice(-2) + '/' + ("0" + mes).slice(-2) + '/' + ano;
        }else{
            return null;
        }

    }
}
export default formatData;