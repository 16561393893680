import React, { useState, useEffect } from "react";
import { Form, Row, Container, Col } from "react-bootstrap";
import "./index.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave } from "@fortawesome/free-solid-svg-icons";
import UsuarioService from "../../../services/UsuarioService";
import { trackPromise } from "react-promise-tracker";
import Swal from "sweetalert2";
import utilsService from "../../../services/utils";
import {
  cpfMask,
  celularMask,
  cepMask,
  identidadeMask,
  apenasNumeros,
} from "../../../components/InputTextMask";
import CpfCnpj from "@react-br-forms/cpf-cnpj-mask";

export default function NovoUsuario({ history, usuario, atualizaLista }) {
  const [nome, setNome] = useState(usuario.nome);
  const [sobrenome, setSobrenome] = useState(usuario.sobrenome);
  const [email, setEmail] = useState(usuario.email);
  const [password, setPassword] = useState("");
  const [telefone, setTelefone] = useState(usuario.telefone);
  const [documento, setDocumento] = useState(usuario.documento);
  const [cep, setCep] = useState(usuario.cep);
  const [cidade, setCidade] = useState(usuario.cidade);
  const [pastaAberta, setPastaAberta] = useState(usuario.pasta_drive_aberta);
  const [pastaRestrita, setPastaRestrita] = useState(
    usuario.pasta_drive_fechada
  );
  const [bairro, setBairro] = useState(usuario.bairro);
  const [estado, setEstado] = useState(usuario.estado);
  const [endereco, setEndereco] = useState(usuario.endereco);
  const [tipo, setTipo] = useState(usuario.tipo);
  const [mask, setMask] = useState("");

  const [tipos, setTipos] = useState([]);

  useEffect(() => {
    trackPromise(
      UsuarioService.buscarTipos().then(
        (response) => {
          setTipos(response.data);
        },
        (error) => {
          Swal.fire({
            titleText: "Ooops...",
            text: "Ocorreu um erro ao buscar dependências: " + error,
            icon: "error",
          });
        }
      )
    );
  }, []);

  function buscaCep(cep) {
    trackPromise(
      utilsService.buscarPorCep(cep).then(
        (response) => {
          const dados = response.data;
          setBairro(dados.bairro);
          setCidade(dados.localidade);
          setEndereco(dados.logradouro);
          setEstado(dados.uf);
        },
        (error) => {
          Swal.fire({
            titleText: "Ooops...",
            text: "Ocorreu um erro ao buscar o cep: " + error,
            icon: "error",
          });
        }
      )
    );
  }
  function salvarUsuario() {
    const dados = {
      nome: nome,
      sobrenome: sobrenome,
      telefone: apenasNumeros(telefone),
      documento: apenasNumeros(documento),
      cep: apenasNumeros(cep),
      email: email,
      cidade: cidade,
      bairro: bairro,
      estado: estado,
      endereco: endereco,
      tipo: tipo,
      pasta_drive_aberta: pastaAberta,
      pasta_drive_fechada: pastaRestrita,
    };
    if (password != null && password != "" && password != undefined) {
      dados.password = password;
    }

    if (
      email == "" ||
      email == null ||
      email == undefined ||
      documento == "" ||
      documento == null ||
      documento == undefined ||
      nome == "" ||
      nome == null ||
      nome == undefined ||
      password == null ||
      password == "" ||
      password == undefined
    ) {
      Swal.fire({
        titleText: "Preencha todos os campos obrigatórios",
        icon: "warning",
      });
      return;
    }
    UsuarioService.salvarUsuario(dados, usuario.id).then(
      (response) => {
        Swal.fire({
          titleText: "Sucesso",
          text: "Usuario salvo com sucesso",
          icon: "success",
        });
      },
      (error) => {
        Swal.fire({
          titleText: "Ooops...",
          text: error.response.data,
          icon: "error",
        });
      }
    );
  }

  return (
    <>
      <Form className="formCadastro">
        <Row>
          <Form.Group as={Col} controlId="formNome">
            <Form.Label>Tipo: </Form.Label>
            <Form.Control
              as="select"
              value={tipo}
              readonly={tipo > 3 ? true : false}
              custom
              onChange={(event) => setTipo(event.target.value)}
            >
              <option>Selecione o tipo</option>
              {tipos.length > 0 ? (
                tipos.map((tipoUser, index) => (
                  <option value={tipoUser.id}>{tipoUser.nome}</option>
                ))
              ) : (
                <></>
              )}
            </Form.Control>
          </Form.Group>
        </Row>
        <Row>
          <Form.Group as={Col} md="6" controlId="formNome">
            <Form.Label>
              Nome: <span style={{ color: "red" }}>*</span>
            </Form.Label>
            <Form.Control
              type="text"
              value={nome}
              placeholder="Digite o nome"
              onChange={(event) => setNome(event.target.value)}
            />
          </Form.Group>
          <Form.Group as={Col} md="6" controlId="formNome">
            <Form.Label>
              Sobrenome: <span style={{ color: "red" }}>*</span>
            </Form.Label>
            <Form.Control
              type="text"
              value={sobrenome}
              placeholder="Digite o nome"
              onChange={(event) => setSobrenome(event.target.value)}
            />
          </Form.Group>
        </Row>
        <Row>
          <Form.Group as={Col} md="6" controlId="formNome">
            <Form.Label>Telefone: </Form.Label>
            <Form.Control
              type="text"
              value={celularMask(telefone)}
              placeholder="Digite o telefone"
              onChange={(event) => setTelefone(celularMask(event.target.value))}
            />
          </Form.Group>
          <Form.Group as={Col} md="6" controlId="formNome">
            <Form.Label>
              Documento: <span style={{ color: "red" }}>*</span>
            </Form.Label>
            <CpfCnpj
              value={documento}
              className="form-control"
              placeholder="Digite o CPF ou CNPJ"
              onChange={(event, type) => {
                setDocumento(event.target.value);
                setMask(type === "CPF");
              }}
            />
          </Form.Group>
        </Row>
        <Row>
          <Form.Group as={Col} controlId="formNome">
            <Form.Label>CEP:</Form.Label>
            <Form.Control
              type="text"
              placeholder="Digite o cep"
              value={cepMask(cep)}
              onBlur={(event) => buscaCep(event.target.value)}
              onChange={(event) => setCep(event.target.value)}
            />
          </Form.Group>
          <Form.Group as={Col} md="8" controlId="formNome">
            <Form.Label>Endereço:</Form.Label>
            <Form.Control
              type="text"
              placeholder="Digite o endereço"
              value={endereco}
              onChange={(event) => setEndereco(event.target.value)}
            />
          </Form.Group>
        </Row>
        <Row className="lastRow">
          <Form.Group as={Col} controlId="formNome">
            <Form.Label>Bairro:</Form.Label>
            <Form.Control
              type="text"
              placeholder="Digite o bairro"
              value={bairro}
              onChange={(event) => setBairro(event.target.value)}
            />
          </Form.Group>
          <Form.Group as={Col} controlId="formNome">
            <Form.Label>Cidade:</Form.Label>
            <Form.Control
              type="text"
              placeholder="Digite a cidade"
              value={cidade}
              onChange={(event) => setCidade(event.target.value)}
            />
          </Form.Group>
          <Form.Group as={Col}>
            <Form.Label>Estado:</Form.Label>
            <Form.Control
              as="select"
              custom
              value={estado}
              onChange={(event) => setEstado(event.target.value)}
            >
              <option value="">Selecione o estado</option>
              <option value="AC">Acre</option>
              <option value="AL">Alagoas</option>
              <option value="AP">Amapá</option>
              <option value="AM">Amazonas</option>
              <option value="BA">Bahia</option>
              <option value="CE">Ceará</option>
              <option value="DF">Distrito Federal</option>
              <option value="ES">Espírito Santo</option>
              <option value="GO">Goiás</option>
              <option value="MA">Maranhão</option>
              <option value="MT">Mato Grosso</option>
              <option value="MS">Mato Grosso do Sul</option>
              <option value="MG">Minas Gerais</option>
              <option value="PA">Pará</option>
              <option value="PB">Paraíba</option>
              <option value="PR">Paraná</option>
              <option value="PE">Pernambuco</option>
              <option value="PI">Piauí</option>
              <option value="RJ">Rio de Janeiro</option>
              <option value="RN">Rio Grande do Norte</option>
              <option value="RS">Rio Grande do Sul</option>
              <option value="RO">Rondônia</option>
              <option value="RR">Roraima</option>
              <option value="SC">Santa Catarina</option>
              <option value="SP">São Paulo</option>
              <option value="SE">Sergipe</option>
              <option value="TO">Tocantins</option>
              <option value="EX">Estrangeiro</option>
            </Form.Control>
          </Form.Group>
        </Row>
        <Row>
          <Form.Group as={Col} md="6" controlId="formNome">
            <Form.Label>
              E-mail: <span style={{ color: "red" }}>*</span>
            </Form.Label>
            <Form.Control
              type="text"
              value={email}
              placeholder="Digite o e-mail"
              onChange={(event) => setEmail(event.target.value)}
            />
          </Form.Group>
          <Form.Group as={Col} md="6" controlId="formNome">
            <Form.Label>
              Senha: <span style={{ color: "red" }}>*</span>
            </Form.Label>
            <Form.Control
              type="password"
              placeholder="Digite o senha"
              onChange={(event) => setPassword(event.target.value)}
            />
          </Form.Group>
        </Row>
        <Row>
          <Form.Group as={Col} controlId="formNome">
            <Form.Label>ID da pasta restrita:</Form.Label>
            <Form.Control
              type="text"
              placeholder="Digite o ID da pasta restrita"
              value={pastaRestrita}
              onChange={(event) => setPastaRestrita(event.target.value)}
            />
          </Form.Group>
          <Form.Group as={Col} controlId="formNome">
            <Form.Label>ID da pasta liberada:</Form.Label>
            <Form.Control
              type="text"
              placeholder="Digite o ID da pasta liberada para edição"
              value={pastaAberta}
              onChange={(event) => setPastaAberta(event.target.value)}
            />
          </Form.Group>
        </Row>
        <Row className="formFooter">
          <Col>
            <button
              type="button"
              onClick={() => salvarUsuario()}
              className="btn btn-success"
            >
              <FontAwesomeIcon icon={faSave} style={{ marginRight: "15px" }} />
              Salvar
            </button>
          </Col>
        </Row>
      </Form>
    </>
  );
}
