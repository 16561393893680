import React, { useState } from 'react';
import './index.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faPlus} from "@fortawesome/free-solid-svg-icons";

function AddButton({clickBotao}) {
	return (
		<>
			<div className="buttonNovaAcao" onClick={() => clickBotao(true)}>
				<FontAwesomeIcon icon={faPlus} color="#fff" />
			</div>
		</>
	)
}

export default AddButton;