import React, { useState, useEffect } from 'react';
import './index.css';
import Modal from '../../components/Modal';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Swal from "sweetalert2";
import { trackPromise } from "react-promise-tracker";
import UsuarioService from '../../services/UsuarioService';
import ReactTooltip from 'react-tooltip';
import TabelaPaginada from "../../components/TabelaPaginada";
import formatData from '../../helpers/format';
import HeaderPagina from "../../components/HeaderPagina";
import AddButton from "../../components/AddButton";
import { realMoeda } from "../../components/InputTextMask";
import { Dropdown } from "react-bootstrap";
import { faPencilAlt, faArrowLeft, faHouseUser, faPhoneSquareAlt, faLaptopMedical, faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import NovoUsuario from './NovoUsuario';
function Usuarios() {
	const [openNovoUsuario, setOpenNovoUsuario] = useState(false);
	const [usuarioSelecionado, setUsuarioSelecionado] = useState({});
	const [lista, setLista] = useState([]);

	useEffect(() => {
		buscarUsuarios()
	}, []);
	function buscarUsuarios() {
		trackPromise(
			UsuarioService.buscarUsuarios().then(
				(response) => {
					setLista(response.data);
				},
				(error) => {
					Swal.fire({
						titleText: "Ooops...",
						text: "Ocorreu um erro ao buscar a lista: " + error,
						icon: "error"
					});
				}
			));
	}

	function novoUsuario() {
		setUsuarioSelecionado({});
		setOpenNovoUsuario(true);
	}
	function fecharModal() {
		buscarUsuarios({});
		setOpenNovoUsuario(false);
	}
	function excluirUsuario(id) {
		Swal.fire({
			titleText: "Deseja excluir o usuario " + '#' + formatData.zeroPad(id, 3) + "?",
			text: "Tem certeza que deseja excluir o usuario " + '#' + formatData.zeroPad(id, 3) + "? Os dados desta não serão mais visíveis no sistema.",
			icon: 'question',
			showCloseButton: false,
			showCancelButton: true,
			focusConfirm: false,
			confirmButtonText: 'Sim, excluir!',
			cancelButtonText: 'Não, mantenha.',
			customClass: {
				confirmButton: 'btn btn-primary mr-3',
				cancelButton: 'btn btn-danger'
			},
			buttonsStyling: false
		}).then(
			(result) => {
				if (result.isConfirmed) {
					trackPromise(
						UsuarioService.excluirUsuario(id).then(
							(response) => {
								buscarUsuarios({});
								Swal.fire({
									titleText: "Sucesso",
									text: "Usuario excluido com sucesso",
									icon: "success"
								});

							},
							(error) => {
								Swal.fire({
									titleText: "Ooops...",
									text: "Ocorreu um erro ao excluir: " + error,
									icon: "error"
								});
							}
						));
				}
			})
	}
	const linhas = lista.map((usuario, index) => <tr key={usuario.id}>
		<td>
			{formatData.zeroPad(usuario.id, 3)}
		</td>
		<td>
			{usuario.nome} {usuario.sobrenome}
		</td>
		<td>
			{usuario.email}
		</td>
		<td>
			{usuario.tipo_usuario_nome}
		</td>
		<td >
			<Dropdown className="dropdownNaoDistribuido">
				<Dropdown.Toggle variant="primary">
					<FontAwesomeIcon
						icon={faPencilAlt} />
				</Dropdown.Toggle>
				<Dropdown.Menu>
					<Dropdown.Item as="button" onClick={() => { setOpenNovoUsuario(true); setUsuarioSelecionado(usuario) }}
						style={{ color: '#000' }}><FontAwesomeIcon
							icon={faEdit} style={{ marginRight: '30px' }} />Alterar Usuario
                            </Dropdown.Item>
					<Dropdown.Item as="button" onClick={() => { excluirUsuario(usuario.id) }}
						style={{ color: '#000' }}><FontAwesomeIcon
							icon={faTrash} style={{ marginRight: '30px' }} />Excluir Usuario
                            </Dropdown.Item>
				</Dropdown.Menu>
			</Dropdown>
		</td>
	</tr>);

	const thead = (
		<tr>
			<th>
				Código
        	</th>
			<th>
				Nome
        	</th>
			<th>
				E-mail
        	</th>
			<th>
				Tipo
        	</th>
			<th className="text-center" style={{ width: '10%' }}>Ações</th>
		</tr>
	);
	return <>
		<HeaderPagina titulo="Usuários" />
		<AddButton clickBotao={() => novoUsuario()} />
		<div className="containerTabela">
			<TabelaPaginada linhasCabecalho={thead} optionsTabela={{}} linhasCorpo={linhas} />
		</div>
		<ReactTooltip />
		<Modal open={openNovoUsuario} close={() => fecharModal(false)}
			content={<NovoUsuario usuario={usuarioSelecionado} atualizaLista={() => buscarUsuarios()} />}
			titulo="Usuário" />
	</>
}
export default Usuarios;
