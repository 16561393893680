import api from './api';

const DashboardService = {
	buscarDashboards: (cpf) => {
		if (cpf != null && cpf != "") {
			return api.get('/dashboards/'+cpf);
		} else {
			return api.get('/dashboards');

		}

	},
	salvarCobranca: (cobranca, id) => {
		let uri = '/dashboards';
		let method = 'post';
		if (id != undefined) {
			uri = '/dashboards/' + id;
			method = 'put'
		}
		return api[method].call(api, uri, { cobranca: cobranca });
	},
	excluirCobranca: (id) => {
		return api['delete'].call(api, '/dashboards/' + id);
	},
};

export default DashboardService;