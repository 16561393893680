import React, { useState, useEffect } from "react";
import api from "../../services/api";
import "../Login/index.css";
import Swal from "sweetalert2";
import logo from "../../assets/logo.png";

export default function Login({ history }) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [sistema, setSistema] = useState(1);
  const [error, setError] = useState(false);
  useEffect(() => {
    valida();
  }, []);
  useEffect(() => {
    localStorage.setItem("sistemaIbraga", sistema.toString());
  }, [sistema]);
  async function valida() {
    var token = localStorage.getItem("tokenEgendDrive");
    if (
      localStorage.getItem("tokenEgendDrive") != null &&
      localStorage.getItem("userEgendDrive") != null
    ) {
      const response = await api.get("/usuario/autentica", { token });
      const { valido } = response.data;
      if (valido) {
        history.push("/");
      }
    }
  }

  async function handleSubmit(event) {
    event.preventDefault();

    const response = await api.post("/usuario/autentica", { email, password });
    const {
      id_usuario,
      valido,
      tipo,
      token,
      documento,
      pasta_drive_aberta,
      pasta_drive_fechada
    } = response.data;
    if (valido) {
      localStorage.setItem("tokenEgendDrive", token.toString());
      localStorage.setItem("userEgendDrive", id_usuario.toString());
      localStorage.setItem("tipoEgendDrive", tipo.toString());
      localStorage.setItem("documentoEgendDrive", documento.toString());
      pasta_drive_fechada && localStorage.setItem("pastaDriveFechada", pasta_drive_fechada.toString());
      pasta_drive_aberta && localStorage.setItem("pastaDriveAberta", pasta_drive_aberta.toString());
      history.push("/");
      window.location.reload();
    } else {
      setError(true);
    }
  }

  async function redefinirSenha() {
    if (email == "" || email == undefined || email == null) {
      Swal.fire({
        titleText: "Recuperação de senha",
        text: "Preencha o e-mail acima antes de solicitar",
        icon: "warning",
      });
      return;
    }
    const response = await api.post("/usuario/recuperar-senha", { email });
    if (response.status == 200) {
      Swal.fire({
        titleText: "Sucesso!",
        text: response.data,
        icon: "success",
      });
      history.push("/login");
    } else {
      Swal.fire({
        titleText: "Ocorreu um erro ao alterar a senha",
        text: response.data,
        icon: "error",
      });
    }
  }
  return (
    <div className="login">
      <div className="imagemLogin">
        <div className="imagemFundoLogin"></div>
      </div>
      <div className="container">
        <div className="logo-egend">
          <img src={logo} alt="EGEND" />
        </div>
        <div className="content">
          <p>
            <strong>Faça o login para continuar: </strong>
          </p>

          <form onSubmit={handleSubmit}>
            <label htmlFor="sistema">SISTEMA</label>
            <select
              value={sistema}
              onChange={(event) => setSistema(event.target.value)}
            >
              <option value="1">Contabilidade</option>
              <option value="2">Advocacia</option>
              <option value="3">Imobiliária</option>
            </select>
            <label htmlFor="email">E-MAIL</label>
            <input
              type="email"
              id="email"
              placeholder="Digite seu e-mail"
              value={email}
              onChange={(event) => setEmail(event.target.value)}
            />

            <label htmlFor="password">SENHA</label>
            <input
              type="password"
              id="password"
              value={password}
              placeholder="Digite sua senha"
              onChange={(event) => setPassword(event.target.value)}
            />
            {error ? (
              <p className="error">Usuário ou senha inválido.</p>
            ) : (
              <></>
            )}

            <div className="buttons">
              <button className="btn btn-submit" type="submit">
                ENTRAR
              </button>
            </div>
            <div className="buttons">
              <button
                className="btn btn-submit"
                type="button"
                onClick={() => history.push("/register")}
              >
                FAÇA O SEU CADASTRO
              </button>
            </div>
            <a
              style={{
                textAlign: "center",
                marginTop: "5px",
                fontWeight: "bold",
                fontSize: "12px",
                textTransform: "uppercase",
                cursor: "pointer",
              }}
              onClick={() => redefinirSenha()}
            >
              Recuperar senha
            </a>
          </form>
        </div>
      </div>
    </div>
  );
}
