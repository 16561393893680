import React, { useState, useEffect } from 'react';
import { Form, Row, Container, Col } from 'react-bootstrap';
import './index.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faSave, faShoppingCart } from "@fortawesome/free-solid-svg-icons";
import ProdutoService from '../../../services/ProdutoService';
import { trackPromise } from "react-promise-tracker";
import Swal from "sweetalert2";
import { formatarParaMascaraMoedaComSeparadorDeMilhares } from "../../../components/InputTextMask";
import pedidoService from '../../../services/PedidoService';

export default function NovoPedido({ history, produto, atualizaLista }) {
    const [tipos, setTipos] = useState([]);
    const [status, setStatus] = useState(produto.status);
    const [statusList, setStatusList] = useState([]);
    let tipoUsuario = localStorage.getItem('tipoEgendDrive');

    useEffect(() => {

        trackPromise(
            pedidoService.buscarTipos().then(
                (response) => {
                    setTipos(response.data);
                },
                (error) => {
                    Swal.fire({
                        titleText: "Ooops...",
                        text: "Ocorreu um erro ao buscar dependências: " + error,
                        icon: "error"
                    });
                }
            )
        )
        trackPromise(
            pedidoService.buscarStatus().then(
                (response) => {
                    setStatusList(response.data);
                },
                (error) => {
                    Swal.fire({
                        titleText: "Ooops...",
                        text: "Ocorreu um erro ao buscar dependências: " + error,
                        icon: "error"
                    });
                }
            )
        )
    }, []);

    function atualizarStatus() {
        trackPromise(
            pedidoService.atualizarPedido(produto.id, status).then(
                (response) => {
                    Swal.fire({
                        titleText: "Sucesso",
                        text: "Pedido atualizado com sucesso.",
                        icon: "success"
                    });
                },
                (error) => {
                    Swal.fire({
                        titleText: "Ooops...",
                        text: "Ocorreu ao atualizar pedido" + error,
                        icon: "error"
                    });
                }
            )
        )
    }
    return <>

        <Form className="formCadastro">
            <Row>
                <Form.Group as={Col} controlId="formNome">
                    <Form.Label>Tipo de solicitação: </Form.Label>
                    <Form.Control as="select" value={produto.tipo_pedido} custom disabled>
                        <option value="0">O que você deseja solicitar? </option>
                        {tipos.map((tipo, index) => <option key={tipo.id} value={tipo.id}>{tipo.nome}</option>)}
                    </Form.Control>
                </Form.Group>
            </Row>
            <Row>
                <Form.Group as={Col} controlId="formNome">
                    <Form.Label>Status do pedido: </Form.Label>
                    <Form.Control as="select" value={status} custom onChange={(event) => setStatus(event.target.value)} disabled={tipoUsuario == 4} >
                        <option value="0">Status do pedido</option>
                        {statusList.map((status, index) => <option key={status.id} value={status.id}>{status.nome}</option>)}
                    </Form.Control>
                </Form.Group>
            </Row>
            <Row>
                <Form.Group as={Col} md="2" controlId="formNome">
                    <Form.Label>Tipo: </Form.Label>
                    <Form.Control type="text" value={produto.tipo_produto_nome} disabled />
                </Form.Group>
                <Form.Group as={Col} md="6" controlId="formNome">
                    <Form.Label>Produto: </Form.Label>
                    <Form.Control type="text" value={produto.produto_nome} disabled />
                </Form.Group>
                <Form.Group as={Col} md="2" controlId="formNome">
                    <Form.Label>Prazo de finalização: </Form.Label>
                    <Form.Control type="text" value={produto.prazo != 0 && produto.prazo != null ? produto.prazo + " dia(s)" : "Indefinido"} disabled />
                </Form.Group>
                <Form.Group as={Col} md="2" controlId="formNome">
                    <Form.Label>Valor: </Form.Label>
                    <Form.Control type="text" value={"R$ " + formatarParaMascaraMoedaComSeparadorDeMilhares(produto.valor_referencia)} disabled />
                </Form.Group>
            </Row>
            <Row>
                <Form.Group as={Col} controlId="formNome">
                    <Form.Label>Observação sobre o pedido: </Form.Label>
                    <textarea name="observacao" className="form-control" id="observacao" value={produto.observacao} cols="30" disabled rows="10"></textarea>
                </Form.Group>
            </Row>
            {tipoUsuario != 4 ?
                <Row className="formFooter">
                    <Col>
                        <button type="button" onClick={() => atualizarStatus()} className="btn btn-success" disabled={status < produto.status ? true : false}><FontAwesomeIcon icon={faCheck}
                            style={{ marginRight: '15px' }} />Atualizar pedido
                        </button>
                    </Col>
                </Row> : <></>}
        </Form>
    </>
}