import React, { useState, useEffect } from 'react';
import api from '../../services/api';
import '../Register/index.css';
import logo from '../../assets/logo.png';
import { Form, Row, Container, Col } from 'react-bootstrap';
import { cpfMask, celularMask, cepMask } from "../../components/InputTextMask";
import utilsService from '../../services/utils';
import Swal from "sweetalert2";
import CpfCnpj from "@react-br-forms/cpf-cnpj-mask";

export default function Register({ history }) {
    const [nome, setNome] = useState('');
    const [sobrenome, setSobrenome] = useState('');
    const [cpf, setCpf] = useState('');
    const [telefone, setTelefone] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [endereco, setEndereco] = useState('');
    const [cidade, setCidade] = useState('');
    const [estado, setEstado] = useState('');
    const [cep, setCep] = useState('');
    const [mask, setMask] = useState("");
    const [error, setError] = useState(false);
    function buscaCep(cep) {
        utilsService.buscarPorCep(cep).then(
            (response) => {
                const dados = response.data;
                setCidade(dados.localidade);
                setEndereco(dados.logradouro);
                setEstado(dados.uf);
            },
            (error) => {
                Swal.fire({
                    titleText: "Ooops...",
                    text: "Ocorreu um erro ao buscar o cep: " + error,
                    icon: "error"
                });
            }
        )

    }
    async function handleSubmit(event) {
        event.preventDefault();
        const response = await api.post('/usuario', {
            nome: nome,
            sobrenome: sobrenome,
            documento: cpf,
            telefone: telefone,
            endereco: endereco,
            cidade: cidade,
            estado: estado,
            cep: cep,
            email: email,
            password: password,
        });
        const { id_usuario, valido, token } = response.data;
        if (valido) {
            localStorage.setItem('tokenEgendDrive', token.toString());
            localStorage.setItem('userEgendDrive', id_usuario.toString());
            history.push('/');
            window.location.reload();
        } else {
            Swal.fire({
                titleText: "Ocorreu um erro ao cadastrar",
                text: response.data,
                icon: "error"
            });
            setError(true);
        }


    }
    return (<div className="register">
        <div className="imagemLogin">
            <div className="imagemFundoLogin"></div>
        </div>
        <div className="container">
            <div className="logo-egend">
                <img src={logo} alt="EGEND" />
            </div>
            <div className="content">

                <p><strong>Cadastre-se para continuar: </strong></p>

                <Form className="formCadastro" onSubmit={handleSubmit}>
                    <Row>
                        <Form.Group as={Col} md="6" controlId="formNome">
                            <Form.Label>Nome: </Form.Label>
                            <Form.Control type="text" placeholder="Digite o nome" onChange={(event) => setNome(event.target.value)} required />
                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="formNome">
                            <Form.Label>Sobrenome: </Form.Label>
                            <Form.Control type="text" placeholder="Digite o sobrenome" onChange={(event) => setSobrenome(event.target.value)} required />
                        </Form.Group>

                    </Row>
                    <Row>
                        <Form.Group as={Col} controlId="formNome">
                            <Form.Label>Documento (CPF ou CNPJ):</Form.Label>
                            <CpfCnpj
                                value={cpf}
                                className="form-control"
                                placeholder="Digite o CPF ou CNPJ"
                                onChange={(event, type) => {
                                    setCpf(event.target.value);
                                    setMask(type === "CPF");
                                }}
                            />
                        </Form.Group>
                        <Form.Group as={Col} md="4" controlId="formNome">
                            <Form.Label>Telefone: </Form.Label>
                            <Form.Control type="text" placeholder="Digite o seu telefone" onChange={(event) => setTelefone(celularMask(event.target.value))} required />
                        </Form.Group>

                    </Row>
                    <Row>
                        <Form.Group as={Col} controlId="formNome">
                            <Form.Label>CEP:</Form.Label>
                            <Form.Control type="text" placeholder="Digite o cep" value={cepMask(cep)} onBlur={(event) => buscaCep(event.target.value)} onChange={(event) => setCep(event.target.value)} />
                        </Form.Group>
                        <Form.Group as={Col} md="8" controlId="formNome">
                            <Form.Label>Endereço:</Form.Label>
                            <Form.Control type="text" placeholder="Digite o endereço" value={endereco} onChange={(event) => setEndereco(event.target.value)} />
                        </Form.Group>

                    </Row>
                    <Row >
                        <Form.Group as={Col}>
                            <Form.Label>Cidade:</Form.Label>
                            <Form.Control type="text" placeholder="Digite a cidade" value={cidade} onChange={(event) => setCidade(event.target.value)} />
                        </Form.Group>
                        <Form.Group as={Col}>
                            <Form.Label>Estado:</Form.Label>
                            <Form.Control as="select" custom value={estado} onChange={(event) => setEstado(event.target.value)}>
                                <option value="">Selecione o estado</option>
                                <option value="AC">Acre</option>
                                <option value="AL">Alagoas</option>
                                <option value="AP">Amapá</option>
                                <option value="AM">Amazonas</option>
                                <option value="BA">Bahia</option>
                                <option value="CE">Ceará</option>
                                <option value="DF">Distrito Federal</option>
                                <option value="ES">Espírito Santo</option>
                                <option value="GO">Goiás</option>
                                <option value="MA">Maranhão</option>
                                <option value="MT">Mato Grosso</option>
                                <option value="MS">Mato Grosso do Sul</option>
                                <option value="MG">Minas Gerais</option>
                                <option value="PA">Pará</option>
                                <option value="PB">Paraíba</option>
                                <option value="PR">Paraná</option>
                                <option value="PE">Pernambuco</option>
                                <option value="PI">Piauí</option>
                                <option value="RJ">Rio de Janeiro</option>
                                <option value="RN">Rio Grande do Norte</option>
                                <option value="RS">Rio Grande do Sul</option>
                                <option value="RO">Rondônia</option>
                                <option value="RR">Roraima</option>
                                <option value="SC">Santa Catarina</option>
                                <option value="SP">São Paulo</option>
                                <option value="SE">Sergipe</option>
                                <option value="TO">Tocantins</option>
                                <option value="EX">Estrangeiro</option>
                            </Form.Control>
                        </Form.Group>

                    </Row>
                    <Row>
                        <Form.Group as={Col} md="6">
                            <Form.Label>E-mail: </Form.Label>
                            <Form.Control type="email" placeholder="Digite o seu e-mail" onChange={(event) => setEmail(event.target.value)} required />
                        </Form.Group>
                        <Form.Group as={Col} md="6" >
                            <Form.Label>Senha: </Form.Label>
                            <Form.Control type="password" placeholder="Digite a sua senha" onChange={(event) => setPassword(event.target.value)} required />
                        </Form.Group>
                    </Row>
                    {error ? <p className="error" >Ocorreu um erro ao cadastrar, verifique os dados e tente novamente.</p> : <></>}

                    <div className="buttons">
                        <button className="btn btn-submit" type="submit">CADASTRAR</button>

                    </div>

                </Form>
            </div>

        </div>
    </div>
    )
}
