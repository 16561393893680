import React, { useState, useEffect } from 'react';
import { Form, Row, Container, Col } from 'react-bootstrap';
import './index.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave } from "@fortawesome/free-solid-svg-icons";
import CupomService from '../../../services/CupomService';
import { trackPromise } from "react-promise-tracker";
import Swal from "sweetalert2";

export default function NovoCupom({ history, cupom, atualizaLista }) {
    const [nome, setNome] = useState(cupom.nome);
    const [codigo, setCodigo] = useState(cupom.codigo);
    const [valor, setValor] = useState(cupom.valor);
    const [tipo, setTipo] = useState(cupom.tipo);
    const [limite, setLimite] = useState(cupom.limite);

    function salvarCupom() {
        const dados = {
            'nome': nome,
            'codigo': codigo,
            'valor': valor,
            'tipo': tipo,
            'limite': limite,
        };
        CupomService.salvarCupom(dados, cupom.id).then(
            (response) => {
                Swal.fire({
                    titleText: "Sucesso",
                    text: "Cupom salvo com sucesso",
                    icon: "success"
                });


            },
            (error) => {
                Swal.fire({
                    titleText: "Ooops...",
                    text: "Ocorreu um erro ao salvar o cupom: " + error,
                    icon: "error"
                });
            }
        )
    }


    return <>

        <Form className="formCadastro">

            <Row>
                <Form.Group as={Col} controlId="formNome">
                    <Form.Label>Tipo: </Form.Label>
                    <Form.Control as="select" value={tipo} custom onChange={(event) => setTipo(event.target.value)}>
                        <option>Selecione o tipo</option>
                        <option value="V">Valor</option>
                        <option value="P">Porcentagem</option>
                    </Form.Control>
                </Form.Group>
                <Form.Group as={Col} controlId="formNome">
                    <Form.Label>Código: </Form.Label>
                    <Form.Control type="text" value={codigo} placeholder="Digite o codigo" onChange={(event) => setCodigo(event.target.value)} />
                </Form.Group>
            </Row>
            <Row>
                <Form.Group as={Col} md="6" controlId="formNome">
                    <Form.Label>Nome: </Form.Label>
                    <Form.Control type="text" value={nome} placeholder="Digite o nome" onChange={(event) => setNome(event.target.value)} />
                </Form.Group>
                <Form.Group as={Col} md="3" controlId="formNome">
                    <Form.Label>Valor: </Form.Label>
                    <Form.Control type="text" value={valor} placeholder="Digite o valor do cupom" onChange={(event) => setValor(event.target.value)} />
                </Form.Group>
                <Form.Group as={Col} md="3" controlId="formNome">
                    <Form.Label>Limite de uso: </Form.Label>
                    <Form.Control type="text" value={limite} placeholder="Digite o limite de uso" onChange={(event) => setLimite(event.target.value)} />
                </Form.Group>
            </Row>
            <Row className="formFooter">
                <Col>
                    <button type="button" onClick={() => salvarCupom()} className="btn btn-success"><FontAwesomeIcon icon={faSave}
                        style={{ marginRight: '15px' }} />Salvar
					</button>
                </Col>
            </Row>
 
        </Form>
    </> 
}