import React, { useEffect, useState } from "react";
import { Switch, Route, withRouter, useHistory } from "react-router-dom";
import api from "./services/api";

import Dashboard from "./pages/Dashboard";
import Produtos from "./pages/Produtos";
import ProdutosPedidos from "./pages/ProdutosPedidos";
import Pedidos from "./pages/Pedidos";
import Cupons from "./pages/Cupons";
import Arquivos from "./pages/Arquivos";
import Usuarios from "./pages/Usuarios";
import Login from "./pages/Login";
import Register from "./pages/Register";
import Recupera from "./pages/Recupera";
import Base from "./base.js";
import { trackPromise } from "react-promise-tracker";

export default function Routes({}) {
  const [tokenValido, setTokenValido] = useState(false);
  const history = useHistory();
  var recuperar = history.location.pathname.split("/");

  useEffect(() => {
    if (
      history.location.pathname != "/register" &&
      recuperar[1] != "recuperar" &&
      tokenValido == false
    ) {
      trackPromise(valida());
    }
  }, []);

  async function valida() {
    var token = localStorage.getItem("tokenEgendDrive");
    if (
      localStorage.getItem("tokenEgendDrive") != null &&
      localStorage.getItem("userEgendDrive") != null
    ) {
      const response = await api.post("/usuario/autentica", { token });
      const { valido, pasta_drive_aberta, pasta_drive_fechada } = response.data;
      pasta_drive_fechada &&
        localStorage.setItem(
          "pastaDriveFechada",
          pasta_drive_fechada.toString()
        );
      pasta_drive_aberta &&
        localStorage.setItem("pastaDriveAberta", pasta_drive_aberta.toString());
      setTokenValido(valido);
      if (!valido) {
        history.push("/login");
      }
    } else {
      history.push("/login");
    }
  }

  return (
    <Switch>
      <Route path="/login" exact component={Login} />
      <Route path="/register" exact component={Register} />
      <Route path="/recuperar/:token" exact component={Recupera} />
      {tokenValido && (
        <Base
          history={history}
          conteudo={
            <div style={{ height: "100%" }}>
              <Route path="/" exact component={Dashboard} />
              <Route path="/produtos" exact component={Produtos} />
              <Route path="/lista-produtos" exact component={ProdutosPedidos} />
              <Route path="/pedidos" exact component={Pedidos} />
              <Route path="/usuarios" exact component={Usuarios} />
              <Route path="/arquivos" exact component={Arquivos} />
              <Route path="/cupom" exact component={Cupons} />
            </div>
          }
        ></Base>
      )}
    </Switch>
  );
}
