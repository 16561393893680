import axios from 'axios';

const utilsService = {
    
	buscarPorCep:(cep)=>{
        const viacep = axios.create({
            baseURL: 'https://viacep.com.br/ws/',
        })
		return viacep.get(cep+'/json');
	},


};

export default utilsService;