import React, { useState, useEffect } from 'react';
import './index.css';
import Modal from '../../components/Modal';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Swal from "sweetalert2";
import { trackPromise } from "react-promise-tracker";
import PedidoService from '../../services/PedidoService';
import ReactTooltip from 'react-tooltip';
import TabelaPaginada from "../../components/TabelaPaginada";
import formatData from '../../helpers/format';
import HeaderPagina from "../../components/HeaderPagina";
import AddButton from "../../components/AddButton";
import { formatarParaMascaraMoedaComSeparadorDeMilhares } from "../../components/InputTextMask";
import { Dropdown } from "react-bootstrap";
import { faPencilAlt, faArrowLeft, faHouseUser, faPhoneSquareAlt, faLaptopMedical, faEdit, faTrash, faInfo } from "@fortawesome/free-solid-svg-icons";
import NovoPedido from './NovoPedido';
function Pedidos() {
	const [openNovoPedido, setOpenNovoPedido] = useState(false);
	const [pedidoSelecionado, setPedidoSelecionado] = useState({});
	const [lista, setLista] = useState([]);

	useEffect(() => {
		buscarPedidos()
	}, []);
	function buscarPedidos() {
		trackPromise(
			PedidoService.buscarPedidos().then(
				(response) => {
					setLista(response.data);
				},
				(error) => {
					Swal.fire({
						titleText: "Ooops...",
						text: "Ocorreu um erro ao buscar a lista: " + error,
						icon: "error"
					});
				}
			));
	}

	function novoPedido() {
		setPedidoSelecionado({});
		setOpenNovoPedido(true);
	}
	function fecharModal() {
		buscarPedidos({});
		setOpenNovoPedido(false);
	}
	function excluirPedido(id) {
		Swal.fire({
			titleText: "Deseja excluir o pedido " + '#' + formatData.zeroPad(id, 3) + "?",
			text: "Tem certeza que deseja excluir o pedido " + '#' + formatData.zeroPad(id, 3) + "? Os dados desta não serão mais visíveis no sistema.",
			icon: 'question',
			showCloseButton: false,
			showCancelButton: true,
			focusConfirm: false,
			confirmButtonText: 'Sim, excluir!',
			cancelButtonText: 'Não, mantenha.',
			customClass: {
				confirmButton: 'btn btn-primary mr-3',
				cancelButton: 'btn btn-danger'
			},
			buttonsStyling: false
		}).then(
			(result) => {
				if (result.isConfirmed) {
					trackPromise(
						PedidoService.excluirPedido(id).then(
							(response) => {
								buscarPedidos({});
								Swal.fire({
									titleText: "Sucesso",
									text: "Pedido excluido com sucesso",
									icon: "success"
								});

							},
							(error) => {
								Swal.fire({
									titleText: "Ooops...",
									text: "Ocorreu um erro ao excluir: " + error,
									icon: "error"
								});
							}
						));
				}
			})
	}
	const linhas = lista.map((pedido, index) => <tr key={pedido.id}>
		<td>
			{formatData.zeroPad(pedido.id, 3)}
		</td>
		<td>
			{pedido.tipo_pedido_nome}
		</td>
		<td>
			<b>{pedido.status_pedido_nome}</b>
		</td>
		<td>
			{pedido.produto_nome}
		</td>
		<td>
			{pedido.prazo_referencia != 0 && pedido.prazo_referencia != null ? pedido.prazo_referencia + " dia(s)" : "Indefinido"}
		</td>
		<td>
			R$ {formatarParaMascaraMoedaComSeparadorDeMilhares(pedido.valor_referencia)}
		</td>
		<td >
			<Dropdown className="dropdownNaoDistribuido">
				<Dropdown.Toggle variant="primary">
					<FontAwesomeIcon
						icon={faPencilAlt} />
				</Dropdown.Toggle>
				<Dropdown.Menu>
					<Dropdown.Item as="button" onClick={() => { setPedidoSelecionado(pedido); setOpenNovoPedido(true) }}
						style={{ color: '#000' }}><FontAwesomeIcon
							icon={faInfo} style={{ marginRight: '30px' }} />Visualizar pedido
                            </Dropdown.Item>
				</Dropdown.Menu>
			</Dropdown>
		</td>
	</tr>);

	const thead = (
		<tr>
			<th>
				Código
        	</th>
			<th>
				Tipo
        	</th>
			<th>
				Status
        	</th>
			<th>
				Nome
        	</th>
			<th>
				Prazo
        	</th>
			<th>
				Valor
        	</th>
			<th className="text-center" style={{ width: '10%' }}>Ações</th>
		</tr>
	);
	return <>
		<HeaderPagina titulo="Pedidos" />
		<div className="containerTabela">
			<TabelaPaginada linhasCabecalho={thead} optionsTabela={{}} linhasCorpo={linhas} />
		</div>
		<ReactTooltip />
		<Modal open={openNovoPedido} close={() => fecharModal(false)}
			content={<NovoPedido produto={pedidoSelecionado} atualizaLista={() => buscarPedidos()} />}
			titulo={"Pedido #" + formatData.zeroPad(pedidoSelecionado.id, 3)} />
	</>
}
export default Pedidos;
