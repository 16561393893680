import React from 'react';
import './App.css';
import './assets/poppins/poppins.css';

import 'bootstrap/dist/css/bootstrap.min.css';
import EgendPromiseLoading from "./components/EgendLoading/EgendPromiseLoading";

import Routes from './routes';
import {BrowserRouter} from "react-router-dom";

function App() {

	return (
		<BrowserRouter>
			<EgendPromiseLoading/>

			<Routes/>
		</BrowserRouter>

	);
}

export default App;
