import React, { useState, useEffect } from 'react';
import './index.css';
import Modal from '../../components/Modal';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Form, Row, Container, Col } from 'react-bootstrap';
import Swal from "sweetalert2";
import { trackPromise } from "react-promise-tracker";
import ProdutoService from '../../services/ProdutoService';
import ReactTooltip from 'react-tooltip';
import TabelaPaginada from "../../components/TabelaPaginada";
import formatData from '../../helpers/format';
import HeaderPagina from "../../components/HeaderPagina";
import AddButton from "../../components/AddButton";
import { formatarParaMascaraMoedaComSeparadorDeMilhares } from "../../components/InputTextMask";
import NovoPedido from './NovoPedido';
function ProdutosPedidos() {
	const [openNovoProduto, setOpenNovoProduto] = useState(false);
	const [produtoSelecionado, setProdutoSelecionado] = useState({});
	const [lista, setLista] = useState([]);

	useEffect(() => {
		buscarProdutos()
	}, []);
	function buscarProdutos() {
		setOpenNovoProduto(false);

		trackPromise(
			ProdutoService.buscarProdutos().then(
				(response) => {
					setLista(response.data);
				},
				(error) => {
					Swal.fire({
						titleText: "Ooops...",
						text: "Ocorreu um erro ao buscar a lista: " + error,
						icon: "error"
					});
				}
			));
	}

	function fecharModal() {
		buscarProdutos({});
		setOpenNovoProduto(false);
	}

	return <>
		<HeaderPagina titulo="Contrate um serviço ou adquira um produto" />
		<div className="containerTabela">
			<Container className="themed-container" fluid={true}>
				<Row>
					{lista.map((produto, index) =>
						<div className="col-12 col-md-3" key={produto.id} data-tip={produto.descricao} onClick={() => { setOpenNovoProduto(true); setProdutoSelecionado(produto) }}>
							<div className="produtoPedido" >
								<div className="textoPedido">
									<div><strong>Nome:</strong>  {produto.nome}</div>
									<div><strong>Prazo de finalização:</strong>  {produto.prazo != 0 ? produto.prazo + " dias" : "Indefinido"}</div>
									{produto.descricao ? <div><strong>Descrição:</strong> {produto.descricao}</div> : <></>}
								</div>
								<div className="finalPedido">
									<div className="clique">
										Clique para mais informações
									</div>
									<div className="valorProdutoPedido">
										R$ {formatarParaMascaraMoedaComSeparadorDeMilhares(produto.valor)}
									</div>
								</div>
							</div>
						</div>)}
				</Row>
			</Container>
		</div>
		<ReactTooltip />
		<Modal open={openNovoProduto} close={() => fecharModal(false)}
			content={<NovoPedido produto={produtoSelecionado} atualizaLista={() => buscarProdutos()} />}
			titulo={"Realizando um novo pedido de: " + produtoSelecionado.nome} />
	</>
}
export default ProdutosPedidos;
