import React, { useState, useEffect } from 'react';
import './index.css';
import Modal from '../../components/Modal';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Swal from "sweetalert2";
import { trackPromise } from "react-promise-tracker";
import CupomService from '../../services/CupomService';
import ReactTooltip from 'react-tooltip';
import TabelaPaginada from "../../components/TabelaPaginada";
import formatData from '../../helpers/format';
import HeaderPagina from "../../components/HeaderPagina";
import AddButton from "../../components/AddButton";
import { formatarParaMascaraMoedaComSeparadorDeMilhares } from "../../components/InputTextMask";
import { Dropdown } from "react-bootstrap";
import { faPencilAlt, faArrowLeft, faHouseUser, faPhoneSquareAlt, faLaptopMedical, faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import NovoCupom from './NovoCupom';
function Cupons() {
	const [openNovoCupom, setOpenNovoCupom] = useState(false);
	const [cupomSelecionado, setCupomSelecionado] = useState({});
	const [lista, setLista] = useState([]);

	useEffect(() => {
		buscarCupons()
	}, []);
	function buscarCupons() {
		trackPromise(
			CupomService.buscarCupons().then(
				(response) => {
					setLista(response.data);
				},
				(error) => {
					Swal.fire({
						titleText: "Ooops...",
						text: "Ocorreu um erro ao buscar a lista: " + error,
						icon: "error"
					});
				}
			));
	}

	function novoCupom() {
		setCupomSelecionado({});
		setOpenNovoCupom(true);
	}
	function fecharModal() {
		buscarCupons({});
		setOpenNovoCupom(false);
	}
	function excluirCupom(id) {
		Swal.fire({
			titleText: "Deseja excluir o cupom " + '#' + formatData.zeroPad(id, 3) + "?",
			text: "Tem certeza que deseja excluir o cupom " + '#' + formatData.zeroPad(id, 3) + "? Os dados desta não serão mais visíveis no sistema.",
			icon: 'question',
			showCloseButton: false,
			showCancelButton: true,
			focusConfirm: false,
			confirmButtonText: 'Sim, excluir!',
			cancelButtonText: 'Não, mantenha.',
			customClass: {
				confirmButton: 'btn btn-primary mr-3',
				cancelButton: 'btn btn-danger'
			},
			buttonsStyling: false
		}).then(
			(result) => {
				if (result.isConfirmed) {
					trackPromise(
						CupomService.excluirCupom(id).then(
							(response) => {
								buscarCupons({});
								Swal.fire({
									titleText: "Sucesso",
									text: "Cupom excluido com sucesso",
									icon: "success"
								});

							},
							(error) => {
								Swal.fire({
									titleText: "Ooops...",
									text: "Ocorreu um erro ao excluir: " + error,
									icon: "error"
								});
							}
						));
				}
			})
	}
	const linhas = lista.map((cupom, index) => <tr key={cupom.id}>
		<td>
			{cupom.codigo}
		</td>
		<td>
			{cupom.nome}
		</td>
		<td>
			{cupom.tipo == 'V' ? "R$ "+formatarParaMascaraMoedaComSeparadorDeMilhares(cupom.valor) : cupom.valor+"%"}
		</td>
		<td>
			{cupom.tipo}
		</td>
		<td >
			<Dropdown className="dropdownNaoDistribuido">
				<Dropdown.Toggle variant="primary">
					<FontAwesomeIcon
						icon={faPencilAlt} />
				</Dropdown.Toggle>
				<Dropdown.Menu>
					<Dropdown.Item as="button" onClick={() => { setOpenNovoCupom(true); setCupomSelecionado(cupom) }}
						style={{ color: '#000' }}><FontAwesomeIcon
							icon={faEdit} style={{ marginRight: '30px' }} />Alterar Cupom
                            </Dropdown.Item>
					<Dropdown.Item as="button" onClick={() => { excluirCupom(cupom.id) }}
						style={{ color: '#000' }}><FontAwesomeIcon
							icon={faTrash} style={{ marginRight: '30px' }} />Excluir Cupom
                            </Dropdown.Item>
				</Dropdown.Menu>
			</Dropdown>
		</td>
	</tr>);

	const thead = (
		<tr>
			<th>
				Código
        	</th>
			<th>
				Nome
        	</th>
			<th>
				Valor
        	</th>
			<th>
				Tipo
        	</th>
			<th className="text-center" style={{ width: '10%' }}>Ações</th>
		</tr>
	);
	return <>
		<HeaderPagina titulo="Cupons" />
		<AddButton clickBotao={() => novoCupom()} />
		<div className="containerTabela">
			<TabelaPaginada linhasCabecalho={thead} optionsTabela={{}} linhasCorpo={linhas} />
		</div>
		<ReactTooltip />
		<Modal open={openNovoCupom} close={() => fecharModal(false)}
			content={<NovoCupom cupom={cupomSelecionado} atualizaLista={() => buscarCupons()} />}
			titulo="Cupom" />
	</>
}
export default Cupons;
