import api from './api';

const pedidoService = {
	buscarPedidos: () => {
		return api.get('/pedido');
	},
	buscarTipos: () => {
		return api.get('/pedido/tipos');
	},
	buscarStatus: () => {
		return api.get('/pedido/status');
	},
	salvarPedido: (pedido, id) => {
		let uri = '/pedido';
		let method = 'post';
		if (id != undefined) {
			uri = '/pedido/' + id;
			method = 'put'
		}
		return api[method].call(api, uri, pedido);
	},
	atualizarPedido: (pedido, status) => {
		let uri = '/pedido/atualiza-status';
		let method = 'post';
		return api[method].call(api, uri, { pedido, status });
	},
};

export default pedidoService;