import React, {useState} from 'react';
import './index.css';

function HeaderPagina(props) {
	return (
		<>
			<div className="headerPagina">
				{props.titulo}
			</div>
		</>
	)
}

export default HeaderPagina;