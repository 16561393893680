import api from './api';

const usuarioService = {
	buscarUsuarios: () => {
		return api.get('/usuario');
	},
	buscarTipos: () => {
		return api.get('/usuario/tipos');
	},
	salvarUsuario: (usuario, id) => {
		let uri = '/usuario';
		let method = 'post';
		if (id != undefined) {
			uri = '/usuario/' + id;
			method = 'put'
		}
		return api[method].call(api, uri, usuario);
	},
	excluirUsuario: (id) => {
		let dados = {
			ativo: '0',
			id: id
		};
		return api['delete'].call(api, '/usuario/' + id, { usuario: dados });
	},
};

export default usuarioService;