import React, { useState, useEffect } from 'react';
import './index.css';
import Modal from '../../components/Modal';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Swal from "sweetalert2";
import { trackPromise } from "react-promise-tracker";
import DashboardService from '../../services/DashboardService';
import ReactTooltip from 'react-tooltip';
import TabelaPaginada from "../../components/TabelaPaginada";
import formatData from '../../helpers/format';
import HeaderPagina from "../../components/HeaderPagina";
import { formatarParaMascaraMoedaComSeparadorDeMilhares } from "../../components/InputTextMask";
import { Form, Row, Container, Col, Dropdown } from "react-bootstrap";
import { faPencilAlt, faArrowLeft, faHouseUser, faPhoneSquareAlt, faLaptopMedical, faEdit, faTrash, faReceipt, faFileInvoiceDollar, faFilter } from "@fortawesome/free-solid-svg-icons";
import AddButton from '../../components/AddButton';
import NovaCobranca from '../Dashboard/NovaCobranca';
import { isMobile } from "react-device-detect";
import usuarioService from '../../services/UsuarioService';

function Dashboard() {
	const [openNovaCobranca, setOpenNovaCobranca] = useState(false);
	const [openRelatorios, setOpenRelatorios] = useState(false);
	const [lista, setLista] = useState([{}]);
	const [listaFull, setListaFull] = useState([{}]);
	const [cobrancaSelecionada, setCobrancaSelecionada] = useState({});
	const [usuarios, setUsuarios] = useState([]);
	const [documentoFiltro, setDocumentoFiltro] = useState(null);

	useEffect(() => {
		buscarDashboards()
	}, []);
	function fecharCobranca() {
		setOpenNovaCobranca(false);
		setCobrancaSelecionada({});
	}
	function buscarDashboards() {
		trackPromise(
			DashboardService.buscarDashboards(documentoFiltro).then(
				(response) => {
					setListaFull(response.data);
					setLista(response.data.todas)
				},
				(error) => {
					Swal.fire({
						titleText: "Ooops...",
						text: "Ocorreu um erro ao buscar a lista: " + error,
						icon: "error"
					});
				}
			)
		);
	}
	function abrirLink(url) {
		const link = document.createElement('a');
		link.setAttribute("target", "_blank")
		link.href = url;
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	}
	function editarCobranca(cobranca) {
		setCobrancaSelecionada(cobranca);
		setOpenNovaCobranca(true);
	}
	function deletarCobranca(id) {
		Swal.fire({
			titleText: "Deseja excluir a cobrança " + id.split("_")[1] + "?",
			text: "Tem certeza que deseja excluir o produto " + id.split("_")[1] + "? Os dados desta não serão mais visíveis no sistema.",
			icon: 'question',
			showCloseButton: false,
			showCancelButton: true,
			focusConfirm: false,
			confirmButtonText: 'Sim, excluir!',
			cancelButtonText: 'Não, mantenha.',
			customClass: {
				confirmButton: 'btn btn-primary mr-3',
				cancelButton: 'btn btn-danger'
			},
			buttonsStyling: false
		}).then(
			(result) => {
				if (result.isConfirmed) {
					trackPromise(
						DashboardService.excluirCobranca(id).then(
							(response) => {
								buscarDashboards();
								if (response.data.valido) {
									Swal.fire({
										titleText: "Sucesso",
										text: "Cobrança excluida com sucesso",
										icon: "success"
									});
								} else {
									Swal.fire({
										titleText: "Ooops...",
										text: response.data.mensagem,
										icon: "error"
									});
								}

							},
							(error) => {
								Swal.fire({
									titleText: "Ooops...",
									text: "Ocorreu um erro ao excluir: " + error,
									icon: "error"
								});
							}
						));
				}
			})
	}
	const linhas = lista.length > 0 ? lista.map((cobranca, index) => <tr key={cobranca.id}>
		{localStorage.getItem('tipoEgendDrive') <= 3 && <td>
			{cobranca.id != undefined ? cobranca.id.split("_")[1] : ''}
		</td>}
		<td>
			{cobranca.description}
		</td>
		<td>
			R$ {formatarParaMascaraMoedaComSeparadorDeMilhares(cobranca.value)}
		</td>
		<td>
			{cobranca.status == 'PENDING' ? 'PENDENTE' : 'PAGA'}
		</td>
		<td>
			{formatData.formataBr(cobranca.dueDate)}
		</td>
		<td >
			<Dropdown className="dropdownNaoDistribuido">
				<Dropdown.Toggle variant="primary">
					<FontAwesomeIcon
						icon={faPencilAlt} />
				</Dropdown.Toggle>
				<Dropdown.Menu>
					<Dropdown.Item as="button" onClick={() => { abrirLink(cobranca.bankSlipUrl) }}
						style={{ color: '#000' }}><FontAwesomeIcon
							icon={faFileInvoiceDollar} style={{ marginRight: '30px' }} />Abrir boleto
					</Dropdown.Item>
					<Dropdown.Item as="button" onClick={() => { abrirLink(cobranca.invoiceUrl) }}
						style={{ color: '#000' }}><FontAwesomeIcon
							icon={faReceipt} style={{ marginRight: '30px' }} />Abrir fatura
					</Dropdown.Item>
					{localStorage.getItem('tipoEgendDrive') <= 3 ? <Dropdown.Item as="button" onClick={() => { editarCobranca(cobranca) }}
						style={{ color: '#000' }}><FontAwesomeIcon
							icon={faReceipt} style={{ marginRight: '30px' }} />Editar fatura
					</Dropdown.Item> : <></>}
					{localStorage.getItem('tipoEgendDrive') <= 3 ? <Dropdown.Item as="button" onClick={() => { deletarCobranca(cobranca.id) }}
						style={{ color: '#000' }}><FontAwesomeIcon
							icon={faTrash} style={{ marginRight: '30px' }} />Excluir fatura
					</Dropdown.Item> : <></>}
				</Dropdown.Menu>
			</Dropdown>
		</td>
	</tr>) : '';

	const thead = (
		<tr>
			{localStorage.getItem('tipoEgendDrive') <= 3 && <th>
				Código no Asaas
			</th>}
			<th>
				Descrição
			</th>
			<th>
				Valor
			</th>
			<th>
				Status
			</th>
			<th>
				Vencimento
			</th>

			<th className="text-center" style={{ width: '10%' }}>Ações</th>
		</tr>
	);
	return <>
		<HeaderPagina titulo="Cobranças" />

		<div className="containerTabela">
			<div className="containerBoxes">
				<div className="boxButton boxDashboard" onClick={() => setOpenRelatorios(!openRelatorios)}> {!openRelatorios ? 'Exibir relatório' : 'Ocultar relatório'}  </div>
				{openRelatorios || !isMobile ? <>
					<div className="boxVencidas boxDashboard" onClick={() => listaFull.vencidas != undefined && listaFull.vencidas.length > 0 ? setLista(listaFull.vencidas) : []}> <div>{listaFull.vencidas != undefined ? listaFull.vencidas.length : 0}<br /><br /> vencidas</div> </div>
					<div className="boxVencendo boxDashboard" onClick={() => listaFull.vencendo_hoje != undefined && listaFull.vencendo_hoje.length > 0 ? setLista(listaFull.vencendo_hoje) : []}><div>{listaFull.vencendo_hoje != undefined ? listaFull.vencendo_hoje.length : 0}<br /><br /> vencem hoje</div> </div>
					<div className="boxAVencer boxDashboard" onClick={() => listaFull.pendente != undefined && listaFull.pendente.length > 0 ? setLista(listaFull.pendente) : []}><div>{listaFull.pendente != undefined ? listaFull.pendente.length : 0}<br /><br /> a vencer</div> </div>
					<div className="boxConfirmadas boxDashboard" onClick={() => listaFull.confirmado != undefined && listaFull.confirmado.length > 0 ? setLista(listaFull.confirmado) : []} ><div>{listaFull.confirmado != undefined ? listaFull.confirmado.length : 0}<br /><br /> pagas</div> </div>
					<div className="boxCobrancas boxDashboard" onClick={() => listaFull.todas != undefined && listaFull.todas.length > 0 ? setLista(listaFull.todas) : []} ><div>{listaFull.todas != undefined ? listaFull.todas.length : 0}<br /><br /> no total</div> </div>
				</> : <></>}
			</div>
		</div>
		{localStorage.getItem('tipoEgendDrive') <= 3 ? <div className="headerPagina">
			<Form className="formCadastro" style={{ width: '100%' }}>

				<Row>
					<Form.Group as={Col} controlId="formNome">
					</Form.Group>
					<Form.Group as={Col} controlId="formNome">
					</Form.Group>
					<Form.Group as={Col} controlId="formNome" style={{ marginBottom: '0' }}>

					</Form.Group>
					<Form.Group as={Col} controlId="formNome" style={{ marginBottom: '0', display: "flex" }}>
						<Form.Control type="text" placeholder="Digite um CPF para filtrar" onChange={(event) => setDocumentoFiltro(event.target.value)} style={{ width: '89%', borderTopRightRadius: "0", borderBottomRightRadius: "0" }} />
						<button type="button" onClick={() => buscarDashboards()} className="btn btn-success" style={{ width: '11%', borderTopLeftRadius: "0", borderBottomLeftRadius: "0" }}><FontAwesomeIcon icon={faFilter}
						/>
						</button>
					</Form.Group>

				</Row>
			</Form>
		</div>: <></>}
		<div className="containerTabela">
			<TabelaPaginada linhasCabecalho={thead} optionsTabela={{}} linhasCorpo={linhas} />
		</div>
		<ReactTooltip />
		<Modal open={openNovaCobranca} close={() => fecharCobranca()}
			content={<NovaCobranca cobranca={cobrancaSelecionada} atualizaLista={() => buscarDashboards()} />}
			titulo="Cobrança" />
		{localStorage.getItem('tipoEgendDrive') <= 3 ? <AddButton clickBotao={() => setOpenNovaCobranca(true)} /> : <></>}

	</>
}
export default Dashboard;
