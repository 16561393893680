import React, { useState, useEffect } from "react";
import "./index.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileUpload } from "@fortawesome/free-solid-svg-icons";
import useDrivePicker from "react-google-drive-picker";
import HeaderPagina from "../../components/HeaderPagina";
function Arquivos() {
  const [openPicker, authResponse] = useDrivePicker();
  const handleOpenPicker = () => {
    openPicker({
      clientId: "xxxxxxxxxxxxxxxxx",
      developerKey: "xxxxxxxxxxxx",
      viewId: "DOCS",
      showUploadView: true,
      showUploadFolders: true,
      supportDrives: true,
      multiselect: true,
      setParentFolder: localStorage.getItem("pastaDriveAberta"),
      callbackFunction: (data) => {
        if (data.action === "cancel") {
          console.log("User clicked cancel/close button");
        }
        document.getElementById("openDrive").contentWindow.location.reload();

        console.log(data);
      },
    });
  };
  return (
    <>
      <HeaderPagina titulo="Arquivos" />
      <div className="menuArquivos">
        <button onClick={() => handleOpenPicker()}>
          <FontAwesomeIcon icon={faFileUpload} /> Carregar arquivo
        </button>
      </div>
      <div className="containerTabela">
        <iframe
          src={`https://drive.google.com/embeddedfolderview?id=${localStorage.getItem(
            "pastaDriveFechada"
          )}#grid`}
          style={{ width: "100%", height: "290px", border: "none" }}
        ></iframe>
        <iframe
          id="openDrive"
          src={`https://drive.google.com/embeddedfolderview?id=${localStorage.getItem(
            "pastaDriveAberta"
          )}#grid`}
          style={{ width: "100%", height: "290px", border: "none" }}
        ></iframe>
      </div>
    </>
  );
}
export default Arquivos;
