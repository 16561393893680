import api from './api';

const produtoService = {
	buscarProdutos: () => {
		return api.get('/produto');
	},
	buscarTipos: () => {
		return api.get('/produto/tipos');
	},
	buscarCategorias: () => {
		return api.get('/produto/categorias');
	},
	salvarProduto: (produto, id) => {
		let uri = '/produto';
		let method = 'post';
		if (id != undefined) {
			uri = '/produto/' + id;
			method = 'put'
		}
		return api[method].call(api, uri, { produto: produto });
	},
	excluirProduto: (id) => {
		let dados = {
			ativo: '0',
			id: id
		};
		return api['put'].call(api, '/produto/' + id, { produto: dados });
	},
};

export default produtoService;